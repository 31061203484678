import { permission } from './../../models/onboarding';
import { duration } from './../../models/video';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild, Inject, AfterViewInit, OnDestroy, Renderer2, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgWaveformComponent } from 'ng-waveform';
import { VoiceOverService } from '../services/voice-over.service';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';

@Component({
    selector: 'app-voice-editor-popup',
    templateUrl: './voice-editor-popup.component.html',
    styleUrls: ['./voice-editor-popup.component.scss'],
})
export class VoiceEditorPopupComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('mediaEditorPlayer', { static: false }) mediaEditorPlayer: ElementRef<HTMLVideoElement>;
    @ViewChild('width1', { static: false }) width1: ElementRef;
    @ViewChild('dragableParentContainer', { static: false }) dragableParentContainer: ElementRef;
    @ViewChild('slider', { static: false }) slider: ElementRef;
    @ViewChild('voiceoverWaveform', { static: false })
    voiceoverWaveform: NgWaveformComponent;
    @ViewChild('dragHandleRight') dragHandleRight: ElementRef;
    @ViewChild('dragHandleLeft') dragHandleLeft: ElementRef;
    playerProgressRangeValue: number = 0;
    maxAllowedClipLength = 6;
    zoomRangeField: any = 100;
    volumeMuteField = false;
    volumeRangeField: any = 100;
    fadeInRangeField: any = 3;
    fadeInStartPoint = 0;
    fadeOutRangeField: any = 3;
    fading = false;
    scaleListWidth: number;
    voiceoverSource: any = '';
    scaleAudioListWidth: any = 700;
    voiceItems = [{ left: 0, top: 0, width: 100, height: 40, ts: [0, 30] }];
    media: any;
    bToggleVoiceoverButton: boolean = true;
    dragPosition = { x: 0, y: 0 };
    previewPlayerCurrentTime = 0;
    previewPlayerEndTime = 0;
    minRangeFieldLength = 3;
    playerInfo = {
        player: {
            current_time: 0,
        },
        clip: {
            current_time: 0,
            fpath: '',
            clip_time: 0,
            clip_left: 0,
            duration_width: 100,
            duration: 0,
            thumb_width: 96,
            second_per_pixel: 1,
            selector_width: 1,
            url: '',
        },
        pointer: {
            left: 0,
            seconds: 0,
        },
    };

    loadSlider = true;
    fileEdited = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<VoiceEditorPopupComponent>,
        private renderer: Renderer2,
        private ngZone: NgZone,
        private voiceService: VoiceOverService,
        private settings: ProntoSettingService,
        public videoManager: CreateVideoManagerService,
    ) {}
    ngOnDestroy(): void {
        // throw new Error('Method not implemented.');
    }
    ngAfterViewInit(): void {
        this.media = this.data.media;

        // console.log(' --- After View after Init ...', this.mediaEditorPlayer.nativeElement);
        let __this = this;

        // setTimeout(() => {
        // this.mediaEditorPlayer.nativeElement.onloadeddata = (event) => {
        // console.log('Video data is loaded.');

        this.calculatePostion();

        // this.mediaEditorPlayer.nativeElement.onloadeddata = undefined;
        //};

        // __this.loadEventListeners();

        // console.log('checking video loading', this.mediaEditorPlayer.nativeElement);
        // }, 100);
    }

    ngOnInit(): void {
        // console.log('media ', this.data.media);
        this.media = this.data.media;
        if(this.media.duration){
            if(this.media.duration < 3){
                this.minRangeFieldLength = 0;
            }
        }
        this.voiceoverSource = '../../../assets/audio/test.mp3';
        this.volumeMuteField = this.videoManager.selectedVideo?.vo_mute;
        this.volumeRangeField = this.media.vo_volume ? this.media.vo_volume : 100;
        this.fadeInRangeField = this.media.vo_fadein_duration ? this.media.vo_fadein_duration : this.minRangeFieldLength;
        // console.log( " FADE IN DURATION ", this.fadeInRangeField)
        this.fadeOutRangeField = this.media.vo_fadeout_duration ? Number(this.media.vo_fadeout_duration) : this.minRangeFieldLength;
        this.fading = this.media.vo_apply_fading;
        this.playerInfo.clip.fpath = this.media.fpath;
        this.playerInfo.clip.url = this.media.url;
    }

    playPreviewPlayer() {
        // this.mediaEditorPlayer.nativeElement.play();
        // this.playerProgressRangeValue = this.mediaEditorPlayer.nativeElement.currentTime;
    }

    reLoadPreviewPlayer(firstLoad = false) {
        this.scaleAudioListWidth = this.dragableParentContainer.nativeElement.offsetWidth;
        // console.log(this.media.start_point, this.maxAllowedClipLength, this.media.url);

        if (firstLoad) {
            this.previewPlayerCurrentTime = this.media.start_point;
            this.previewPlayerEndTime = Math.round(this.media.start_point) + Math.round(this.maxAllowedClipLength);
            // this.voiceoverSource = this.media.url;
            // console.log(' load first time ..');
            // this.mediaEditorPlayer.nativeElement.setAttribute('src', this.videoFrameUrl);
        } else {
            this.previewPlayerCurrentTime = this.playerInfo.pointer.seconds;
            this.previewPlayerEndTime = this.playerInfo.pointer.seconds + this.maxAllowedClipLength;
            // this.previewPlayerEndTime = this.playerInfo.pointer.seconds + this.maxAllowedClipLength
            // this.videoFrameUrl = `${this.media.url}#t=${this.playerInfo.pointer.seconds},${
            //     this.playerInfo.pointer.seconds + this.maxAllowedClipLength
            // }`;
        }
        this.updateEndPointOfSelection();
    }
    width: number = 0;
    currentIndex: number = 260;
    selectedTime: number = 0;
    widthVidThumb = 20;
    numbers: any;
    mouseX = 0;
    mouseDirection = '';
    previousPositionXLeft = 0;
    previousPositionXRight = 0;
    prevLeftTransform = '';
    firstXLeft = true;
    firstXRight = true;
    dragStart = false;
    changeIndex(event) {
        let wid = this.width1.nativeElement.getBoundingClientRect().width;
        this.width = wid;
        this.currentIndex = event;
        let valueCursorSec = (event - 240) / (this.scaleListWidth / 30);
        this.selectedTime = parseInt(valueCursorSec + '');
        this.widthVidThumb = (this.width + 70) / this.numbers.length;
    }

    OnVoiceoverPlayButtonClick() {

        // this.voiceoverWaveform.timeUpdate.emit({time: this.playerInfo.pointer.seconds, progress: 16.949018051973816});
        if (this.voiceoverWaveform) {
            this.voiceoverWaveform.play(this.playerInfo.pointer.seconds);
            this.voiceoverWaveform.setRegionStart(this.playerInfo.pointer.seconds);
            let endTime = Number(this.playerInfo.pointer.seconds + this.maxAllowedClipLength).toFixed(2)
            this.voiceoverWaveform.setRegionEnd(Number(endTime));
        }
        this.bToggleVoiceoverButton = false;
    }

    OnAudioPauseButtonClick() {
        if (this.voiceoverWaveform) {
            this.voiceoverWaveform.pause();
        }
        this.bToggleVoiceoverButton = true;
    }
    LoadAudioFile(event) {
        // console.log("VideoTimelineComponent_LoadUploadedBackgroundMusic :==========> ", event);
    }
    renderedAudioFile(event) {
       console.log("CustomEditVideo_RenderUploadedBackgroundMusic :==========> ", event);
    }

    dragStarted($event) {}
    dragEnded($event) {}
    dragSliderEnds($event) {}

    dragOver() {
        // console.log('---- ', this.playerInfo.clip.clip_time, this.playerInfo.clip.second_per_pixel);
    }
    afterDragMovement(x, y) {
        this.playerInfo.pointer.seconds = Math.round(x / this.playerInfo.clip.second_per_pixel);
        if(this.playerInfo.pointer.seconds < -1){
            this.playerInfo.pointer.seconds = 0;
        }
        this.playerInfo.clip.clip_time = this.playerInfo.pointer.seconds;
        // this.reLoadPreviewPlayer(false);
        // this.mediaEditorPlayer.nativeElement.pause();
    }

    async calculatePostion() {
        // console.log('Calculate readings of voice ...', this.media.url, this.playerInfo.clip);
        //Math.round(this.mediaEditorPlayer.nativeElement.duration);
        let durationFile = await this.getFileDuration(this.media.url, this.playerInfo.clip);
        durationFile = durationFile.toFixed(2);
        if(durationFile){
            if(this.media.duration < 3){
                this.minRangeFieldLength = 0;
            }
        }
        this.fadeInRangeField = this.media.vo_fadein_duration ? this.media.vo_fadein_duration : this.minRangeFieldLength;
        this.fadeOutRangeField = this.media.vo_fadeout_duration ? Number(this.media.vo_fadeout_duration) : this.minRangeFieldLength;
        this.playerInfo.clip.duration = durationFile;
        this.maxAllowedClipLength = Number(durationFile);

        this.media.duration = durationFile; // Math.round(this.mediaEditorPlayer.nativeElement.duration);

        let expectedWith = this.playerInfo.clip.duration * 96;

        this.playerInfo.clip.duration_width = this.dragableParentContainer.nativeElement.offsetWidth;

        console.log( " parent widht" , this.getParentWidth(), this.playerInfo.clip.duration_width);
        this.playerInfo.clip.second_per_pixel = this.playerInfo.clip.duration_width / this.playerInfo.clip.duration;
        this.playerInfo.clip.thumb_width = this.playerInfo.clip.second_per_pixel;
        this.playerInfo.clip.clip_left = this.media.start_point * this.playerInfo.clip.second_per_pixel;
        this.dragPosition.x = this.playerInfo.clip.clip_left;
        this.playerInfo.pointer.seconds = this.media.start_point;
        this.playerInfo.pointer.left = this.playerInfo.clip.clip_left;

        this.playerInfo.clip.selector_width = this.maxAllowedClipLength * this.playerInfo.clip.second_per_pixel;

        this.loadSlider = true;

        // setTimeout( ()=> {
        // console.log(" Loading position ", this.slider.nativeElement.style.width , this.playerInfo.clip.selector_width );
        // console.log( " this.dragHandleRightElement.style ", this.dragHandleRightElement.style , this.dragHandleLeftElement.style)
        this.dragHandleRightElement.setAttribute(
            'style',
            'transform : translate3d(' + (this.playerInfo.clip.selector_width - 30) / 2 + 'px, 0px, 0);',
        );
        this.dragHandleLeftElement.setAttribute(
            'style',
            'transform : translate3d(-' + this.playerInfo.clip.selector_width / 2 + 'px, 0px, 0);',
        );
        this.dragHandleRightElement.style.transform = `translate3d(${(this.playerInfo.clip.selector_width - 30) / 2}px, 0px, 0);`;
        this.dragHandleLeftElement.style.transform = `translate3d(-${this.playerInfo.clip.selector_width / 2}px, 0px, 0);`;
        // }, 2000);

        // this.voiceItems.push({ts: [ 1, 10], left: 30});
    }

    refreshAudioList() {
        // console.log(' audio list called ...');
    }
    onVoiceOverPaused() {}
    endPointOfSelection = 0;
    updateEndPointOfSelection() {

        let endPoint = this.playerInfo.clip.selector_width / this.playerInfo.clip.second_per_pixel;
        // console.log(" update end point ", this.playerInfo.clip.selector_width , this.playerInfo.clip.second_per_pixel, " = ", endPoint.toFixed(2));
        this.maxAllowedClipLength = Number(endPoint.toFixed(2));
        this.playerInfo.pointer.seconds = Number((this.getSliderTransform()/this.playerInfo.clip.second_per_pixel).toFixed(2));
        this.updateWavePlayerStartPoint();
    }

    getSliderTransform(){
        return Number(this.settings.transformLeft(this.slider.nativeElement.style.transform));
    }

    OnVoiceoverPauseButtonClick() {}
    async saveVoice() {
        // console.log(" .. save voice totally ... ", this.fileEdited);
        if (this.fileEdited) {
            let data = {
                email: '',
                session_id: '',
                audio_id: this.media.fpath,
                start: Math.round(this.playerInfo.pointer.seconds),
                end: Number(Math.round(this.playerInfo.pointer.seconds + this.maxAllowedClipLength)),
            };

            if(data.end > this.media.duration){
                data.end = Number(this.media.duration);
                // console.log(" DUration was exceeding ...");
            }
            let newVoice = await this.voiceService.cropVoice(data);
            this.media.fpath = newVoice.cropped_id;
            this.media.visual_path = newVoice.cropped_id;
            this.media.url = newVoice.cropped_signed_url;
            this.media.duration = data.end - data.start;
            console.log(" .. editing file ", newVoice.cropped_id);
            // console.log('wait for voice ', newVoice);
            // this.media.fpath  =
            // this.media.url  =
        }
        let durationFade = Number((this.playerInfo.clip.duration - this.fadeOutRangeField).toFixed(2));
        this.videoManager.selectedVideo.vo_mute = this.volumeMuteField;
        this.media.vo_volume = this.volumeRangeField;
        this.media.vo_fadein_start = this.fadeInStartPoint; //this.playerInfo.clip.duration > 6 ? this.fadeInStartPoint : 0;
        this.media.vo_fadein_duration = Number(this.fadeInRangeField);
        this.media.vo_fadeout_duration = Number(this.fadeOutRangeField);
        if(this.media.duration > this.fadeOutRangeField){
            this.media.vo_fadeout_start =Number(Number(this.media.duration) - this.fadeOutRangeField);
        } else {
            this.media.vo_fadeout_start =  Number(Number(this.media.duration) - 2);
        }

        // this.media.vo_fadeout_duration = Number(durationFade.toFixed(2));
        this.media.duration = Number(this.media.duration);
        // this.media.start_from = this.playerInfo.pointer.seconds;
        this.media.vo_apply_fading = this.fading;
        this.dialogRef.close(this.media);
    }

    resetTransformLeftDrag() {
        let transformationWidth = Number(Math.abs(this.playerInfo.clip.selector_width));
        // console.log( " Transform Center ", transformationWidth / 2);
        this.dragHandleRightElement.setAttribute('style', 'transform : translate3d(' + (transformationWidth - 30) / 2 + 'px, 0px, 0);');
        this.dragHandleLeftElement.setAttribute('style', 'transform : translate3d(-' + transformationWidth / 2 + 'px, 0px, 0);');
        this.sliderPrevPostion = transformationWidth;
        // console.log(" RESET NEXT DRAG 301" );
        // this.dragHandleRightElement.style.transform = `translate3d(${(this.playerInfo.clip.selector_width - 30 ) / 2}px, 0px, 0);`;
        // this.dragHandleLeftElement.style.transform = `translate3d(-${this.playerInfo.clip.selector_width / 2 }px, 0px, 0);`;
    }

    getFileDuration(src, obj): Promise<any> {
        return new Promise((resolve, reject) => {
            var audio = new Audio();
            audio.addEventListener('loadedmetadata', function (result) {
                // console.log(' loaded response >>> ', audio.duration);
                resolve(audio.duration);
            });
            // console.log('Loading voice wait ...');
            audio.src = src;
            // audio.onload =
        });
    }

    getOffsetPositionDifference() {
        let parentElm = document.getElementsByClassName('voice-slider-container');
        let parentLeft = 0;

        if (parentElm && parentElm[0]) {
            parentLeft = parentElm[0].getBoundingClientRect().left + 17 - 71;
        }
        return parentLeft;
    }
    getParentWidth() {
        let parentElm = document.getElementsByClassName('voice-slider-container');
        let parentLeft = 0;

        if (parentElm && parentElm[0]) {
            parentLeft = parentElm[0].getBoundingClientRect().width;
        }
        return parentLeft;
    }
    getParentContainer(): any {
        let parentElm = document.getElementsByClassName('voice-slider-container');
        let parentLeft = 0;

        if (parentElm && parentElm[0]) {
            return parentElm[0].getBoundingClientRect();
        }
        return { width: 0 };
    }
    dragRightPeviousPosition = '';
    dragLeftPeviousPosition = '';
    resizeStart = false;
    mouseEvent: any = undefined;
    resizeMove(dragHandle: HTMLElement, $event: CdkDragMove<any>, type = 'x') {
        // if(!this.resizeStart){
        this.resizeStart = true;
        this.fileEdited = true;
        this.mouseEvent = $event.event;

        // console.log(" MOUSE DIRECTION ", this.mouseX , this.mouseEvent?.pageX, this.mouseEvent);
        if (this.mouseX && this.mouseX > this.mouseEvent?.pageX) {
            // console.log(" Moving left");
            this.mouseDirection = 'left';
        } else {
            //  console.log(" Moving right");
            this.mouseDirection = 'right';
        }
        this.mouseX = this.mouseEvent?.pageX;
        let parentElm = document.getElementsByClassName('voice-slider-container')[0].getBoundingClientRect();

        let dragHandler = dragHandle.getBoundingClientRect();

        let leftDiff = dragHandler.left - parentElm.left + dragHandle.clientWidth;

        let widthSlider = Number(this.playerInfo.clip.selector_width);
        let allowMove = true;
        // if(type == '-x'){
        //     console.log(widthSlider , parentElm.width);
        //     allowMove = widthSlider < parentElm.width;
        // }

        let currentTransform = 0;
        currentTransform = this.getSliderTransform();

        // console.log(currentTransform + widthSlider - dragHandler.width , parentElm.width);
        //     console.log( currentTransform,widthSlider,leftDiff, parentElm.width);
        // console.log("size ", parentElm.right, parentElm.left, " transform and width ", currentTransform, widthSlider ,
        // currentTransform + widthSlider);
        let diffSize = widthSlider + currentTransform - 10;
        // console.log( ' parentElm ', dragHandler.left, parentElm.left, parentElm.width , parentElm.width+parentElm.left);
        if (this.mouseDirection == 'left') {
            if (this.playerInfo.clip.selector_width < 30) {
                allowMove = false;
            } else {
                if (type == '-x') {
                    // console.log("Client Left ",this.mouseEvent?.pageX, dragHandler.left, parentElm.left);

                    // console.log(' Moving left ..', this.mouseEvent?.pageX, ' < ', parentElm.left);
                    // console.log( " (dragHandler.left + 0) < parentElm.left ", (dragHandler.left + 0) < parentElm.left, (dragHandler.left) , parentElm.left)
                    if (this.mouseEvent?.pageX + 10 < parentElm.left) {
                        allowMove = false;
                    }
                } else {
                    // console.log(" Right TO Left ", dragHandler.left , (parentElm.left + 50 ), this.slider.nativeElement.style.transform < 50, (dragHandler.left ) <  (parentElm.left + 50 ))
                    if (this.slider.nativeElement.style.transform < 50 || currentTransform < 0) {
                        allowMove = false;
                    }
                }
            }
        } else {
            if (this.playerInfo.clip.selector_width < 30) {
                allowMove = false;
            } else {
                // console.log(" moving left to right ");

                if (type == '-x') {
                    // console.log( " ((parentElm.left + parentElm.width) - dragHandler.left) < 30) ", ((parentElm.left + parentElm.width) - dragHandler.left) < 30), ((parentElm.left + parentElm.width) , dragHandler.left);

                    if (parentElm.left + parentElm.width - this.mouseEvent?.pageX < 30) {
                        allowMove = false;
                    }
                } else {
                    let pos = this.playerInfo.clip.selector_width + currentTransform + parentElm.left;
                    if (pos > parentElm.left + parentElm.width) {
                        allowMove = false;
                    }
                }
            }
        }

        if (!allowMove) {
            console.log(' ****** EXCEED FROM SIDE ');
            this.stopPropogation($event);
            this.resetTransformLeftDrag();
        }

        if (allowMove) {
            // if ((leftDiff + 15) < parentElm.width  && leftDiff > -15 && allowMove ) {
            // if (type == 'x') {
            //     this.dragRightPeviousPosition = dragHandle.style.transform;
            // } else {
            //     this.dragLeftPeviousPosition = dragHandle.style.transform;
            // }

            this.ngZone.runOutsideAngular(() => {
                this.resize(dragHandle, this.resizeBoxElement, type, $event);
            });
        } else {
            // console.log("U can not move ..");
            if (type == 'x') {
                // console.log(" VAlue of right transform ", this.settings.transformLeft(this.dragHandleRightElement.style.transform));
                //  dragHandle.style.transform =  this.dragRightPeviousPosition;
            } else {
                // dragHandle.style.left = this.dragLeftPeviousPosition;
                // dragHandle.style.transform = this.dragLeftPeviousPosition;
            }
            this.stopPropogation($event);
            this.resetTransformLeftDrag();
            this.resizeStart = false;
        }
        // }
    }

    sliderPrevPostion = 0;
    dragMoveSlider($event: any) {
        // console.log(' MOVE SLIDER CALLEd');
        let sliderTransform = this.settings.transformLeft(this.slider.nativeElement.style.transform);
        let { x, y } = $event.source.getFreeDragPosition();
        if (sliderTransform < 0) {
            // console.log("Less than 0 ");
            // this.slider.nativeElement.style.transform = 'translate3d(0px, 0px, 0px); !important';
            this.stopPropogation($event);

        } else {
            // console.log("  CHANGE POSITION ...");
            this.fileEdited = true;


            // console.log(
            //     x,
            //     this.slider.nativeElement.offsetWidth,
            //     x + this.slider.nativeElement.offsetWidth,
            //     this.imgContainer.nativeElement.offsetWidth,
            // );
            // this.resetTransformLeftDrag();
            // console.log("move move ", x, this.playerInfo.clip.selector_width, this.dragableParentContainer.nativeElement.offsetWidth, " transforms :",  this.settings.transformLeft(this.slider.nativeElement.style.transform));

            // console.log('CURRENT TRANSFORMATION ', sliderTransform);
            let dontMove = false;
            if (sliderTransform < 0) {

                // this.slider.nativeElement.style.transform = 'translate3d(0px, 0px, 0px);';
                $event.event.stopPropagation();
                dontMove = true;
            }
            if (sliderTransform + this.playerInfo.clip.selector_width > this.getParentWidth()) {

                // this.slider.nativeElement.style.transform ="translate3d("+this.sliderPrevPostion+"px, 0px, 0px);"
                $event.event.stopPropagation();
                dontMove = true;
            } else {
                if (
                    sliderTransform < 0 ||
                    (x + this.playerInfo.clip.selector_width > this.dragableParentContainer.nativeElement.offsetWidth + 10) || dontMove
                ) {
                    $event.event.stopPropagation();
                    this.reLoadPreviewPlayer();
                    // $event.source.stopPropagation();
                } else {
                    // console.log(" Move it still. .. ");
                    this.sliderPrevPostion = sliderTransform;
                    this.afterDragMovement(x, y);
                    this.reLoadPreviewPlayer();
                }
                this.resetTransformLeftDrag();
            }

        }
        this.afterDragMovement(x, y);

    }
    stopPropogation($event) {
        // console.log("Stop Propogtion ...");
        this.resetTransformLeftDrag();

        // console.log(' can not move');
        // $event.preventDefault();

        // $event.event.preventDefault();
        $event.event.stopImmediatePropagation();
        $event.event.stopPropagation();
        // $event.event.srcElement.stopImmediatePropagation();
        // if($event.event.srcElement){
        //     $event.event.srcElement.stopPropagation();
        // }
        // $event.stopPropagation();
        // let parentElm = document.querySelector('.voice-slider-container');
        if(this.settings.transformLeft(this.slider.nativeElement.style.transform) < 0){
            this.slider.nativeElement.style = `width:${this.playerInfo.clip.selector_width}; translate3d(0px, 0px, 0px) !important;`;
        }
        let { x, y } = $event.source.getFreeDragPosition();

        if( (x + this.playerInfo.clip.selector_width > this.dragableParentContainer.nativeElement.offsetWidth + 10)) {
            let transmf =  this.dragableParentContainer.nativeElement.offsetWidth - this.playerInfo.clip.selector_width;
            this.slider.nativeElement.style = `width:${this.playerInfo.clip.selector_width}; translate3d(${transmf}px, 0px, 0px) !important;`;
        }
        // console.log("transform " ,this.slider.nativeElement.style.transform);
        let parentElm =<HTMLElement> document.querySelector('.media-editor-popup');
        if(parentElm) {

            parentElm.focus();
        }


        if($event.source && $event.source.stopPropagation){
            $event.source.stopPropagation();
            $event.source.stopImmediatePropagation();

            // console.log("Clear Source Move ..");
        }

        // console.log($event);
    }

    resize(dragHandle: HTMLElement, target: HTMLElement, type = 'x', $event: any) {
        let parentLeftDiff = this.getOffsetPositionDifference();
        const dragRect = dragHandle.getBoundingClientRect();
        const targetRect = target.getBoundingClientRect();
        // console.log(targetRect);
        // console.log(' this.slider.nativeElement.style ', this.slider.nativeElement.style.width, this.playerInfo.clip.second_per_pixel);

        let sliderLeft = targetRect.left - parentLeftDiff;
        let dragLeft = dragRect.left - parentLeftDiff;

        // console.log(" this.slider.nativeElement ", parentLEft, sliderLeft, dragRect.width);
        // console.log("left only ", sliderLeft , dragLeft);

        let width = dragLeft - sliderLeft - dragRect.width;
        // let width2 = ((dragRect.left  - targetRect.left) - parentLeftDiff) - dragRect.width ;
        // console.log(" width  ", width, width2);

        let currentTransform = this.settings.transformLeft(this.slider.nativeElement.style.transform);
        let newValueofTranfform = 0;
        if (type != 'x') {
            // let move = '---->>';
            if (this.mouseDirection == 'right') {
                width = this.playerInfo.clip.selector_width - 2; //Number(width);
                newValueofTranfform = Math.round(currentTransform) + 2; //Math.round(width);
            } else {
                // move = '<<----';
                // console.log("Move left ...");
                width = this.playerInfo.clip.selector_width + 2;
                newValueofTranfform = Math.round(currentTransform) - 2; //Math.round(transformValue);
            }
            // console.log(move, " transofrm ", currentTransform , newValueofTranfform, "width" , this.playerInfo.clip.selector_width, width);
            if (newValueofTranfform > -1 && newValueofTranfform + this.playerInfo.clip.selector_width < this.getParentWidth()) {
                this.slider.nativeElement.style.transform = `translate3d(${newValueofTranfform}px, 0px, 0px)`;
                // console.log(" 581 ...");
            }
        } else {
            if (this.mouseDirection == 'right') {
                width = this.playerInfo.clip.selector_width + 2; //Number(width);
            } else {
                width = this.playerInfo.clip.selector_width - 2;
            }
        }

        if (width > this.getParentContainer().width + 10 || width < this.playerInfo.clip.second_per_pixel) {
            this.resetTransformLeftDrag();
        } else {
            // console.log(' width and transofrm ');
            this.playerInfo.clip.selector_width = width;
            this.setAllHandleTransform(type);
        }
        this.resizeStart = false;

        // target.style.height = height + 'px';
    }

    get resizeBoxElement(): HTMLElement {
        return this.slider.nativeElement;
    }
    get dragHandleRightElement(): HTMLElement {
        return this.dragHandleRight.nativeElement;
    }
    get dragHandleLeftElement(): HTMLElement {
        return this.dragHandleLeft.nativeElement;
    }

    setAllHandleTransform(type) {
        const rect = this.resizeBoxElement.getBoundingClientRect();
        // this.setHandleTransform(this.dragHandleCornerElement, rect, 'both');
        //if(type == 'x') {
        //     this.setHandleTransform(this.dragHandleRightElement, rect, 'x');
        //} else {
        //   this.setHandleTransform(this.dragHandleLeftElement, rect, '-x');
        //}

        this.resetTransformLeftDrag();
        this.reLoadPreviewPlayer();

        // this.setHandleTransform(this.dragHandleBottomElement, rect, 'y');
    }
    setHandleTransform(dragHandle: HTMLElement, targetRect: ClientRect | DOMRect, position: 'x' | 'y' | 'both' | '-x') {
        const dragRect = dragHandle.getBoundingClientRect();

        if (position === 'x') {
            const translateX = this.playerInfo.clip.selector_width - dragRect.width;
            if (this.playerInfo.clip.selector_width / 2 > 30) {
                dragHandle.style.transform = `translate3d(${this.playerInfo.clip.selector_width / 2 - 15}px, 0, 0)`;
                // console.log(" 634 ...");
            }
        }
        if (position !== 'x') {
            // const translateX = this.playerInfo.clip.selector_width - dragRect.width;
            if (this.playerInfo.clip.selector_width / 2 > 0 && this.playerInfo.clip.selector_width < 700) {
                dragHandle.style.transform = `translate3d(-${this.playerInfo.clip.selector_width / 2 - 0}px, 0, 0)`;
                // console.log(" 641 ...");
            }
        }
        this.reLoadPreviewPlayer();
        // if (position === 'y') {
        //   dragHandle.style.transform = `translate(0, ${translateY}px)`;
        // }

        // if (position === 'both') {
        //   dragHandle.style.transform = `translate(${translateX}px, ${translateY}px)`;
        // }
        this.updateEndPointOfSelection();
    }

    allowFading(event: any) {
        this.fading = event?.target?.checked;
    }

    returnFileName(filename) {
        if (filename && filename != '') {
            var split = filename.split('.');
            var filename = split[0];
            var extension = split[1];
            if (filename.length > 15) {
                filename = filename.substring(0, 15);
            }
            var result = filename + '.' + extension;
            return result;
        } else {
            return 'file';
        }
    }
    closeEditor() {
        this.dialogRef.close();
    }
    muteVolume(val) {
        this.volumeMuteField = val;
    }

    mouseDown($event) {
        let { x, y } = $event;
        // console.log("mouse ", x, y);
        let mouseEvent = $event.event;
        this.mouseX = mouseEvent.pageX;
        // console.log(' DRAG MOVE DOWN ...');
        if (this.mouseX && x < this.mouseX) {
            this.mouseDirection = 'left';
        } else {
            this.mouseDirection = 'right';
        }
    }
    returnRound(val) {
        let value = Math.floor(val);
        return value;
    }

    playVoiceMedia(e){

        let endTime = Number(this.playerInfo.pointer.seconds + this.maxAllowedClipLength).toFixed(2)
        // console.log( "end time ", e.time, endTime);
         if(e.time > endTime){
        //     console.log( "  pause audio ", this.playerInfo.pointer.seconds);
            this.voiceoverWaveform.pause();
            this.updateWavePlayerStartPoint();


         }

    }
    stopVoiceMedia(e){
        // console.log("audio stopped...");
    }

    updateWavePlayerStartPoint(){
        this.voiceoverWaveform.setRegionStart(this.playerInfo.pointer.seconds);
        let endTime = Number(this.playerInfo.pointer.seconds + this.maxAllowedClipLength).toFixed(2)
        this.voiceoverWaveform.setRegionEnd(Number(endTime));
        this.voiceoverWaveform.pause();
    }

    playerDuratioChanged (e){
        let endTime = Number(this.playerInfo.pointer.seconds + this.maxAllowedClipLength).toFixed(2);

        console.log( " duration changed ", e, endTime);
    }
}
