import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Event, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigManagerService } from 'src/app/Managers/config-manager.service';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { ModifyVideoManagerService } from 'src/app/Managers/modify-video-manager.service';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
import { profile } from 'src/app/models/onboarding';
import { VideoService } from 'src/app/services/dataServices/video.service';
import { ErrorService } from 'src/app/services/error.service';
import { cSessionService } from 'src/app/services/session.service';
import { SuccessMessageModalService } from 'src/app/services/success-message-modal.service';
import { VideoDataStore } from 'src/app/stores/video.store';

@Component({
    selector: 'app-modify-video-wizard',
    templateUrl: './sap-video-wizard.component.html',
    styleUrls: ['./sap-video-wizard.component.scss'],
})
export class SapVideoWizardComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild("editTitleField") inputEle: ElementRef;
    sUserEmail: any;
    sSessionId: any;
    showNext: boolean = false;
    showPrevious: boolean = false;
    showCancel: boolean = false;
    showSave: boolean = false;
    showPreview: boolean = false;
    saveButtonTitle: string = 'Generate';
    constructor(
        public videoManger: CreateVideoManagerService,
        public modifyVideoManager: ModifyVideoManagerService,
        public router: Router,
        private oSessionService: cSessionService,
        public onboardinManger: OnboardingManagerService,
        private config: ConfigManagerService,
        private videoService: VideoService,
        private successService: SuccessMessageModalService,
        public errorService: ErrorService,
        public videoStore: VideoDataStore,
    ) { }


    ngAfterViewInit() {
        this.inputEle.nativeElement.focus();
    }
    sub: Subscription;
    ngOnInit(): void {
        this.modifyVideoManager.isModifyVideo = true;
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

        if (this.sSessionId !== '' && this.sUserEmail !== '') {
            if (!this.onboardinManger.profile) {
                this.getProfile(this.sUserEmail, this.sSessionId);
            } else if (this.config.Templates.length === 0) {
                this.config.getTemplates2(this.sUserEmail, this.sSessionId, this.onboardinManger.profile.allowed_templates);
            }
        }

        this.UpdateMenu();

        this.sub = this.router.events.subscribe((event: Event) => {
            this.UpdateMenu();
        });
    }
    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.modifyVideoManager.isModifyVideo = false;
        this.modifyVideoManager.isPreview = false;
    }

    async getProfile(email, session) {
        await this.onboardinManger.getUserProfile(email, session).then(async (res: profile) => {
            await this.config.getTemplates2(email, session, res.allowed_templates);
        });

        await this.onboardinManger.getUserVideosCount(email, session).then((count) => { });

        this.config.getVoices(email, session).then();
    }

    private UpdateMenu(isVideoGenerationStart = false) {
        // console.log("update preview menu");
        // console.log(this.router.url);

        if (this.router.url === '/mv/overview') {
            if (!this.videoManger.isEditMode) {
                this.showNext = true;
                this.showCancel = true;
                this.showPreview = false;
                this.showPrevious = false;
            } else {
                this.showNext = false;
                this.showCancel = true;
                this.showPreview = false;
                this.showPrevious = false;
                this.showSave = true;
            }
            this.saveButtonTitle = 'Generate';
        } else if (this.router.url === '/mv/looknfeel') {
            this.saveButtonTitle = 'Generate';
            if (!this.videoManger.isEditMode) {
                this.showNext = true;
                this.showCancel = false;
                this.showPreview = false;
                this.showPrevious = true;
            } else {
                this.showNext = false;
                this.showCancel = true;
                this.showPreview = false;
                this.showPrevious = false;
                this.showSave = true;
            }
        } else if (this.router.url === '/mv/content') {
            // this.saveButtonTitle = 'Generate';
            if (!this.videoManger.isEditMode) {
                this.showNext = true;
                this.showCancel = false;
                this.showPreview = false;
                this.showPrevious = true;
            } else {
                this.showNext = true;
                this.showCancel = false;
                this.showPreview = false;
                this.showPrevious = true;
                this.showSave = false;
            }
        } else if (this.router.url === '/mv/other-setting') {
            this.saveButtonTitle = 'Generate';
            if (!this.videoManger.isEditMode) {
                this.showNext = false;
                this.showCancel = false;
                this.showPreview = true;
                this.showPrevious = true;
            } else {
                this.showNext = false;
                this.showCancel = true;
                this.showPreview = false;
                this.showPrevious = false;
                this.showSave = true;
            }
        } else if (this.router.url === '/mv/preview') {
            if (!this.videoManger.isEditMode) {
                this.showNext = false;
                this.showCancel = false;
                this.showPreview = false;
                this.showPrevious = false;
                this.showSave = true;
                this.saveButtonTitle = 'Back';
            } else {
                this.showNext = false;
                this.showCancel = false;
                this.showPreview = false;
                this.showPrevious = false;
                this.showSave = true;
                this.saveButtonTitle = 'Back';
            }
        } else {
            if (this.router.url === '/mv/timeline-view' && this.videoManger.isEditMode) {
                this.saveButtonTitle = 'Generate';
                this.showNext = false;
                if (!isVideoGenerationStart) {
                    this.showCancel = true;
                }
                this.showPreview = false;
                this.showPrevious = false;
                this.showSave = true;
            }
        }
    }

    back() {
        this.router.navigateByUrl('/dashboard/myvideos');
    }
    next() {
        this.videoManger.submitForm.emit();
        this.UpdateMenu();
    }

    save() {
        console.log(' -- hide cancel button ...');
        this.showCancel = false;
        this.videoManger.submitForm.emit('save');
        this.UpdateMenu(true);
    }

    previous() {
        this.videoManger.previousSubmit.emit();
        this.UpdateMenu();
    }
    cancel() {
        console.log('cancel');
        this.router.navigateByUrl('/dashboard/myvideos');
        this.UpdateMenu();
        this.videoStore.lVideoFps[6].value = null;
        this.videoStore.lVideoFps[6].name = '';

    }
    isTimeLineView = false;
    changeview(event) {
        console.log(event.target.checked);
        if (event.target.checked) {
            this.isTimeLineView = false;
            this.router.navigateByUrl('/mv/overview');
        } else {
            this.isTimeLineView = true;
            this.router.navigateByUrl('/mv/timeline-view');
        }
    }
    editTitle(video) {
        video.edit_title = !video.edit_title;
        setTimeout(() => {
            this.inputEle.nativeElement.focus();
        }, 0);
        if (!video.edit_title) {
            if (video.title_temp == '') {
                video.title_temp = this.videoManger.selectedVideo.title;
                this.errorService.handleError('Video Title can not be empty.', 'Edit Video Title', true);
            } else {
                this.updateVideoTitle(video);
            }
        } else {
            video.title_temp = this.videoManger.selectedVideo.title;
        }
    }
    updateTitle(keycode) {
        if (keycode == 13) {
            this.updateVideoTitle(this.videoManger.selectedVideo)
        }
    }
    updateVideoTitle(video) {
        let data = {
            fpath: video.fpath,
            video_id: video.fpath,
            title: video.title_temp,
            session_id: this.sSessionId,
            email: this.sUserEmail,
            ars: video.ars ? video.ars : undefined,
        };
        let promise = new Promise((resolve, reject) => {
            this.videoService
                .updateVideoTitle(data)
                .then((res) => {
                    video.title = video.title_temp;
                    this.successService.showInfoToaster('Video name has been updated.', true, true);
                    resolve(res);
                })
                .catch((err) => {
                    this.errorService.handleError(err.errorMessage, err.errorType, true);
                    reject(err);
                });
        });
    }
}
