import { map } from "rxjs/operators";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { MyAccountService } from "src/app/services/dataServices/my-account.service";
import { brand } from "src/app/models/admin";
import { MediaManagerService } from "src/app/Managers/media-manager.service";
import { cSessionService } from "src/app/services/session.service";

@Component({
  selector: "app-brand-logo-list",
  templateUrl: "./brand-logo-list.component.html",
  styleUrls: ["./brand-logo-list.component.scss"],
})
export class BrandLogoListComponent implements OnInit {
  @Input() brand_id: string;
  @Output('onSelectBrandLogo') selectBrandLogoEmitter = new EventEmitter();
  brandDetail: any = [];
  brandLogoPath: any = [];
  logoSelected: boolean = false;
  showLoader = true;
  dataLoaded = false;
  selectedBrandLogoFile = undefined;
  constructor(
    private adminBrandManager: MyAccountService,
    private mediaManger: MediaManagerService,
    private oSessionService: cSessionService
  ) {}

  listBrandLogo: { fpath: string; url: string; isSelected: boolean, filename : string}[];
  ngOnInit(): void {
    if(this.brand_id){
      this.getBrandProfile();
    }
  }
  getBrandProfile() {
    let __this = this;
    this.adminBrandManager
      .getBrandProfile(this.brand_id)
      .then((brand: brand) => {
        let logoFiles = [];
        __this.brandDetail = brand;
        console.log(__this.brandDetail);
        if (brand.logo_files) {
          logoFiles = brand.logo_files;
        } else {
          if (brand.logo_file && brand.logo_file != "") {
            logoFiles.push(brand.logo_file);
          }
        }
        this.fetchBrandLogoSignedUrl(logoFiles);
      });
  }

  fetchBrandLogoSignedUrl(logoFilesFpath) {
    const fpahArray = logoFilesFpath;
    this.showLoader = true;
    this.mediaManger
      .getMediaThumbandURL(
        this.oSessionService.cSessionService_GetUserEmail(),
        this.oSessionService.SessionService_GetSessionId(),
        fpahArray
      )
      .then((resThumb: any) => {
        this.dataLoaded = true;
        this.showLoader = false;
        if (resThumb && resThumb.output) {
          resThumb.output.forEach((item: any) => {
            this.logoSelected = false;
            const key = Object.keys(item)[0];
            console.log(key == this.brandDetail.logo_file, key , " = ",  this.brandDetail.logo_file);
            const brand = {
              fpath : key,
              filename : key,
              url : item[key],
              isSelected : (key == this.brandDetail.logo_file) ? true : false,
            }
            if(brand.isSelected) {
              this.selectedBrandLogoFile = brand;
            }

              this.brandLogoPath.push(brand);
          });
        }
        if(!this.selectedBrandLogoFile  && this.brandLogoPath.length > 0) {
          this.brandLogoPath[0].isSelected = true;
          this.selectedBrandLogoFile = this.brandLogoPath[0];
        }
      })
      .catch((err) => {
        this.dataLoaded = true;
        this.showLoader = false;
      });
  }

  selectLogo(brand) {
    this.brandLogoPath.map((item: any) => {
      item.isSelected = false;
    });
    brand.isSelected = true;
    this.selectedBrandLogoFile = brand;
  }

  selectBrandLogoEmitterTrigger(){
    this.selectBrandLogoEmitter.emit(this.selectedBrandLogoFile);
  }

}
