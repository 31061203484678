import { item } from './../../modify-video/sap-video-timeline/sap-video-timeline.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { ConfigManagerService } from './../../../Managers/config-manager.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { cDataService } from './../../../services/data.service';
import { cEditVideoService } from './../../../services/edit-video.service';
import { cSessionService } from './../../../services/session.service';
import { ErrorService } from './../../../services/error.service';
import { mediaFile, visual } from './../../../models/media';
import { MediaManagerService } from 'src/app/Managers/media-manager.service';
import { SuccessMessageModalService } from 'src/app/services/success-message-modal.service';
import { BrandService } from '../manage-brand/service/BrandService';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { VideoManagerService } from 'src/app/Managers/video-manager.service';
import { FtCreateVideoManagerService } from 'src/app/Managers/ft-create-video-manager.service';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadFileDialogComponent } from 'src/app/shared/components/upload-file-dialog/upload-file-dialog.component';
var Filter = require('bad-words'),
    filter = new Filter();
@Component({
    selector: 'app-brand-identity',
    templateUrl: './brand-identity.component.html',
    styleUrls: ['./brand-identity.component.scss'],
})
export class BrandsIdentityComponent implements OnInit, OnDestroy {
    sSessionId: string;
    sUserEmail: string;
    logoFile: any;
    oSavedFormData: any;
    bDisplayAlertMsg: boolean = false;
    public fBrandIdentityForm: FormGroup;
    sNewIndustry: string;
    sSelectedIndustry: any;
    oPrimaryColor: any;
    sNewlyAddedIndustry: string;
    sDisplayResponseMessage: string;
    bDisplayErrorMessage: boolean = false;
    bDisplaySuccessMessage: boolean = false;
    selectedIndustry: any;
    oFontColor: any;
    bDisabledButtons: boolean = false;
    bEditButton: boolean = true;
    industries: any = [];
    fillProomaryColor: string;
    primaryColorIconPath: string;
    fillFontColor: string;
    bloadIndustries: boolean = false;
    otherActive = false;
    isModalVisible: boolean = false;
    successMsg: string;
    industryList: any;

    limitIndustryList = 8;
    totalIndustries = 0;
    fontColorIconPath: string;
    destroy$: Subject<boolean> = new Subject<boolean>();
    listTopFiveIndustries = [];
    listTopIndustries = [];
    public primaryColor = undefined;
    public secondaryColor = [
        { id: 0, color: undefined },
    ];

    constructor(
        private onboardingManager: OnboardingManagerService,
        public configManager: ConfigManagerService,
        public accountManger: AccountManagerService,
        private mediaManger: MediaManagerService,
        private oSessionService: cSessionService,
        private oFormBuilder: FormBuilder,
        private oDataService: cDataService,
        private oEditVideoService: cEditVideoService,
        public errorService: ErrorService,
        private successModal: SuccessMessageModalService,
        private brandService: BrandService,
        public videoManager : CreateVideoManagerService,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        this.secondaryColor = [];
        if (this.onboardingManager.profile) {
            this.initializeForm();
        } else {
            this.bDisabledButtons = true;
            this.bEditButton = false;
            this.onboardingManager
                .getUserProfile(
                    this.onboardingManager.loggedUser.email,
                    this.onboardingManager.loggedUser.session_id,
                )
                .then((res) => {
                    this.initializeForm();
                })
                .catch((err) => {});
        }

        // this.mapIconToIndustires();

        this.oSessionService.setAccountSettingHeaderName('Profile');

        this.getIndustry();
    }

    private updateColorPicker() {
        this.fillProomaryColor = 'gray';
        this.primaryColorIconPath = '';

        this.fillFontColor = 'gray';
        this.fontColorIconPath = '';
        setTimeout(() => {
            this.InitializeColorisDataePicker();
        }, 10);
    }

    async initializeForm() {
        this.sSelectedIndustry = this.onboardingManager.profile.industry;
        if (this.onboardingManager.profile.primary_color) {
            this.primaryColor = this.onboardingManager.profile.primary_color;
        }
        if (
            this.onboardingManager.profile.secondary_colors &&
            this.onboardingManager.profile.secondary_colors.length > 0
        ) {
            this.secondaryColor = [];
            this.onboardingManager.profile.secondary_colors.map(
                (color, index) => {
                    this.secondaryColor.push({ id: index, color: color });
                },
            );
        }
        this.fBrandIdentityForm = this.oFormBuilder.group({
            session_id: this.onboardingManager.profile.session_id,
            email: this.onboardingManager.profile.email,
            company_name: this.onboardingManager.profile.company_name
                ? this.onboardingManager.profile.company_name
                : '',
            url: this.onboardingManager.profile.url
                ? this.onboardingManager.profile.url
                : '',
            vertical: this.onboardingManager.profile.industry,

            color: [this.onboardingManager.profile.color],
            font_color: [this.onboardingManager.profile.font_color],
        });

        if (
            this.onboardingManager.profile.logo_file &&
            this.onboardingManager.profile.logo_file != ''
        )
            this.logoFile =
                await this.oEditVideoService.EditVideoService_ReturnFileDetails(
                    this.onboardingManager.profile.logo_file,
                );
        this.oPrimaryColor = this.onboardingManager.profile.color;
        this.oFontColor = this.onboardingManager.profile.font_color;
        this.sSelectedIndustry = this.onboardingManager.profile.industry;

        this.primaryColor = this.onboardingManager.profile.primary_color
            ? this.onboardingManager.profile.primary_color
            : undefined;

        if (this.onboardingManager.profile.secondary_colors) {
            this.secondaryColor = [];
            this.onboardingManager.profile.secondary_colors.map(
                (item, index) => {
                    this.secondaryColor.push({ id: index, color: item });
                },
            );
        } else {
            this.secondaryColor = [{ id: 0, color: undefined }];
        }

        this.updateColorPicker();

        setTimeout(() => {
            this.bDisabledButtons = false;
            this.bEditButton = true;
        }, 10);
    }

    showAll() {
        this.bloadIndustries = true;
        this.limitIndustryList = this.totalIndustries;
    }

    BrandIdentityComponent_GetPrimaryColor(event: any) {
        if (
            event.target.value.toString() != 'false' &&
            event.target.value.toString() != 'true'
        ) {
            const primaryColor = event.target.value;
            console.log(
                'BrandIdentityComponent_GetPrimaryColor : Primary Color ==>',
                primaryColor,
            );

            this.oPrimaryColor = primaryColor;
            this.fillProomaryColor = 'white';
            this.primaryColorIconPath =
                'M14.0907 2.96762C14.5411 2.55307 15.2465 2.56804 15.678 3.00444C16.1227 3.45416 16.1038 4.17511 15.6398 4.60219L6.48078 13.0323C6.04449 13.4339 5.36684 13.4336 4.93088 13.0316L0.359438 8.81657C-0.104226 8.38906 -0.122348 7.66809 0.322706 7.21878C0.754571 6.78279 1.46002 6.76838 1.91006 7.18334L5.70688 10.6842L14.0907 2.96762ZM1.26349 7.83654L1.26326 7.83633Z';
            this.InitializeColorisDataePicker();
        }
    }

    setColorToProperty(event, type, index = undefined) {
        const fontColor = event.target?.defaultValue;
        if (type == 'primary') {
            this.primaryColor = fontColor;
        } else {
            this.secondaryColor[index].color = fontColor;
        }
    }

    BrandIdentityComponent_GetFontColor(event: any) {
        if (
            event.target.value.toString() != 'false' &&
            event.target.value.toString() != 'true'
        ) {
            const fontColor = event.target.value;
            console.log(
                'BrandIdentityComponent_GetFontColor : Font Color ==>',
                fontColor,
            );

            this.oFontColor = fontColor;
            this.fillFontColor = 'white';
            this.fontColorIconPath =
                'M14.0907 2.96762C14.5411 2.55307 15.2465 2.56804 15.678 3.00444C16.1227 3.45416 16.1038 4.17511 15.6398 4.60219L6.48078 13.0323C6.04449 13.4339 5.36684 13.4336 4.93088 13.0316L0.359438 8.81657C-0.104226 8.38906 -0.122348 7.66809 0.322706 7.21878C0.754571 6.78279 1.46002 6.76838 1.91006 7.18334L5.70688 10.6842L14.0907 2.96762ZM1.26349 7.83654L1.26326 7.83633Z';
            this.InitializeColorisDataePicker();
        }
    }

    updateBrandLogo() {
        // this.onboardingManager.profile.logo_file = this.logoFile?.visual_path;

        this.onboardingManager
            .updateLogoFile()
            .then((res) => {
                (this.successMsg = 'Profile Logo'), this.showPopUp();
                // this.sDisplayResponseMessage = "Your Logo is updated successfully.";
                // this.bDisplaySuccessMessage = true;
                // setTimeout(() => {
                //   this.bDisplaySuccessMessage = false;
                // }, 5000);

                // this.bDisabledButtons = false;
                // this.bEditButton = true;
                this.updateColorPicker();
            })
            .catch((err) => {
                // this.sDisplayResponseMessage = InComingMessage['errorMessage'];

                this.sDisplayResponseMessage = err;
                this.bDisplayErrorMessage = true;
                setTimeout(() => {
                    this.bDisplayErrorMessage = false;
                }, 5000);
            });
    }

    update() {
        this.onboardingManager.profile.session_id =
            this.fBrandIdentityForm.get('session_id').value;
        this.onboardingManager.profile.email =
            this.fBrandIdentityForm.get('email').value;
        this.onboardingManager.profile.company_name =
            this.fBrandIdentityForm.get('company_name').value;
        this.onboardingManager.profile.url =
            this.fBrandIdentityForm.get('url').value;
        this.onboardingManager.profile.industry = this.sSelectedIndustry;

        if (
            this.oPrimaryColor &&
            this.oPrimaryColor.toString() != 'true' &&
            this.oPrimaryColor.toString() != 'false'
        )
            this.onboardingManager.profile.color = this.oPrimaryColor;
        if (
            this.oFontColor &&
            this.oFontColor != 'true' &&
            this.oFontColor != 'false'
        )
            this.onboardingManager.profile.font_color = this.oFontColor;

        console.log(
            this.onboardingManager.profile.color +
                '-----' +
                this.onboardingManager.profile.font_color,
        );
        if (this.onboardingManager.profile.organization) {
            delete this.onboardingManager.profile.organization;
        }
        if (this.onboardingManager.profile?.default_media_lib) {
            delete this.onboardingManager.profile.default_media_lib;
        }
        if (this.primaryColor) {
            this.onboardingManager.profile.primary_color = this.primaryColor;
        }

        const colorSecondary = [];
        this.secondaryColor.map((item) => {
            if (item.color && item.color != '') {
                colorSecondary.push(item.color);
            }
        });

        if (colorSecondary.length > 0) {
            this.onboardingManager.profile.secondary_colors = colorSecondary;
        }
        this.onboardingManager
            .updateUserProfile(this.onboardingManager.profile)
            .then((res) => {
                (this.successMsg = 'Profile'), this.showPopUp();

                // this.sDisplayResponseMessage = "Your profile was updated successfully.";
                // this.bDisplaySuccessMessage = true;
                // setTimeout(() => {
                //   this.bDisplaySuccessMessage = false;
                // }, 5000);

                // this.successModal.success("Your profile was updated successfully","", true, true);

                this.bDisabledButtons = false;
                this.bEditButton = true;
                this.updateColorPicker();
            })
            .catch((err) => {
                this.errorService.handleError(
                    err.errorMessage,
                    err.errorType,
                    true,
                );
                // this.sDisplayResponseMessage = InComingMessage['errorMessage'];
                this.bDisplayErrorMessage = true;
                setTimeout(() => {
                    this.bDisplayErrorMessage = false;
                }, 5000);
            });

        this.otherActive = false;
        if (this.industries.length) {
            for (let i = 5; i < this.industries.length; i++) {
                const element = this.industries[i];
                if (element.name === this.sSelectedIndustry) {
                    this.otherActive = true;
                }
            }
        }
    }

    // BrandIdentityComponent_UploadedFile(event) {
    //     let file = event.target.files[0];

    //     if (file) {
    //         let media = new mediaFile();
    //         media.file = file;
    //         media.name = file.name;

    //         this.mediaManger
    //             .uploadMediaDefaultLibrary(
    //                 this.sUserEmail,
    //                 this.sSessionId,
    //                 media,
    //             )
    //             .then((res: visual) => {
    //                 this.logoFile = res;
    //                 this.logoFile.size = file.size;
    //                 this.onboardingManager.profile.logo_file =
    //                     this.logoFile?.visual_path;
    //                 this.updateBrandLogo();
    //             })
    //             .catch((err) => {
    //                 setTimeout(() => {
    //                     this.bDisplayErrorMessage = false;
    //                 }, 5000);
    //                 this.showMediaPopup(err);
    //             });
    //     }
    //     this.mediaManger.replaceMediaFromVisualPopup
    //         .pipe(takeUntil(this.destroy$))
    //         .subscribe((item) => {
    //             if (item && item.length > 0) {
    //                 this.logoFile = item[0];
    //                 this.logoFile.size = file.size;
    //                 this.onboardingManager.profile.logo_file =
    //                     this.logoFile?.visual_path;
    //                 this.updateBrandLogo();
    //             }
    //             this.mediaManger.showMediaReplacementPopup = false;
    //         });
    // }
    BrandIdentityComponent_UploadedFile(){
        const dialogConfig = new MatDialogConfig();
    
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "100wv";
        dialogConfig.height = "100vh";
        dialogConfig.panelClass = "pronto-dialog";
        dialogConfig.closeOnNavigation = true;
    
        dialogConfig.data = {
          uploadFor: "profile_logo",
          multipleUpload: false,
          dropBoxText: "",
          heading: "Upload a Logo",
          allowDuplicate: true,
          mediaLibraryId:this.onboardingManager.profile?.default_media_lib?._id?.$oid,
        };
        
        if (!dialogConfig.data.mediaLibraryId) {
            delete dialogConfig.data.mediaLibraryId;
        }
    
        let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);
    
        dialogModal.afterClosed().subscribe((data: any) => {      
          if (data?.status == "success" || data?.type == "success") {
            console.log("Success called");
            const res = data.result[0];
            console.log("rescheck", res);
            this.logoFile = res;
            this.logoFile.size = res.size;
            this.onboardingManager.profile.logo_file =
                this.logoFile?.visual_path;
            this.updateBrandLogo();
    }
    
});
this.mediaManger.replaceMediaFromVisualPopup.pipe(takeUntil(this.destroy$)).subscribe((item: any) => {
    if (item && item.length > 0) {
            const res = item[0];
            console.log("rescheck", res);
            this.logoFile = res;
            this.logoFile.size = res.size;
            this.onboardingManager.profile.logo_file =
                this.logoFile?.visual_path;
            this.updateBrandLogo();

    }
    this.mediaManger.showMediaReplacementPopup = false;
    // this.cd.detectChanges();
});
    }

    showMediaPopup(error: any) {
        let msgErr = error.errorMessage ? error.errorMessage : error.error;
        let isDuplicateIssue = false;
        let titleErrorBox = 'File Upload Failed';
        let autoClose = true;

        if (msgErr && msgErr.indexOf('Invalid file') > -1) {
            titleErrorBox = "Logo Format Error";
            msgErr = "Oops! Looks like the logo you uploaded isn't in the right format. Please upload a PNG or JPG file to use as your logo.";
            console.log("Invalid file error ");
        }
        if (msgErr && msgErr.indexOf('already exist') > -1) {
            isDuplicateIssue = true;
            titleErrorBox = 'Duplicate File Error';
            autoClose = false;
            this.mediaManger.replaceMediaTypeFromVisualPopup = {
                type: 'image',
                data: [],
            };
        }

        this.errorService.handleError(
            msgErr,
            titleErrorBox,
            true,
            autoClose,
            false,
            isDuplicateIssue,
        );
    }
    BrandIdentityComponent_PatchIndustryValueSideBar(industry: string) {
        this.sSelectedIndustry = industry;
        this.otherActive = false;
    }
    BrandIdentityComponent_AddNewIndustry() {
        this.sNewIndustry = filter.clean(this.sNewIndustry);
        // if (!this.sNewIndustry.includes("*")) {
        //   this.industries.push({
        //     name: this.sNewIndustry,
        //     path: "",
        //     viewBox: "0 0 33 32",
        //   });
        // }
        this.industries.push({
            name: this.sNewIndustry,
        });

        this.sNewlyAddedIndustry = this.sNewIndustry;
        this.sNewIndustry = '';
    }
    BrandIdentityComponent_ReturnFileSize(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    }
    BrandIdentityComponent_EditForm() {
        this.bEditButton = false;
        this.bDisabledButtons = true;
    }
    BrandIdentityComponent_ResetView() {
        this.ngOnInit();
        this.bDisabledButtons = false;
        this.bEditButton = true;
    }
    BrandIdentityComponent_SaveChanges() {}
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    InitializeColorisDataePicker() {
        let chatScript = document.createElement('script');
        chatScript.type = 'text/javascript';
        chatScript.text = `
    Coloris({
      el: '.coloris',

      swatches: [
          '#264653',
          '#2a9d8f',
          '#e9c46a',
          '#f4a261',
          '#e76f51',
          '#d62828',
          '#023e8a',
          '#0077b6',
          '#0096c7',
          '#00b4d8',
          '#48cae4',
      ]
  });
 `;
        document.body.appendChild(chatScript);
    }

    async mapIconToIndustires() {
        if (!this.configManager.metaInfo) {
            await this.configManager.getMetaInfo();
        }
        const oResIndustries = this.configManager.metaInfo.category_map;
        this.industries = [];
        for (const key in oResIndustries) {
            if (Object.prototype.hasOwnProperty.call(oResIndustries, key)) {
                if (
                    key !== 'Entertainment' ||
                    'Agriculture' ||
                    'Manufacturing' ||
                    'Non-Profit'
                ) {
                    this.industries.push({
                        name: key,
                        path: '',
                        viewBox: '0 0 33 32',
                    });
                }
            }
        }

        // this.industries.forEach((outerElement) => {
        //   this.lIndustriesSaved.forEach((innerElement) => {
        //     if (innerElement.name === outerElement.name) {
        //       outerElement.path = innerElement.path;
        //       outerElement.viewBox = innerElement.viewBox;
        //     }
        //   });
        // });
        console.log(
            'CreateAccountCompanyComponent_ Updated Industries == ',
            this.industries,
        );
    }

    visibleBrand = false;
    placementBrand: NzDrawerPlacement = 'right';
    openBrand(): void {
        if (this.bEditButton == false) {
            this.visibleBrand = true;
            this.otherActive = true;
        }
    }
    closeBrand(): void {
        this.visibleBrand = false;
        this.otherActive = false;
    }

    bluntevent($event) {
        $event.target.attributes.placeholder.nodeValue = 'test';
    }
    showPopUp(): void {
        this.isModalVisible = true;
        setTimeout(() => {
            this.isModalVisible = false;
        }, 5000);
    }
    hidePopUP(): void {
        this.isModalVisible = false;
    }

    getIndustry() {
        let __this = this;
        this.brandService.getIndustryList().then((res: any) => {
            this.industryList = res['result'];
            this.industries = res['result'];
            this.totalIndustries = this.industries.length;
            this.filterTopFiveIndustries();

            if (this.industries.length) {
                for (let i = 5; i < this.industries.length; i++) {
                    const element = this.industries[i];
                    if (element.name === this.sSelectedIndustry) {
                        this.otherActive = true;
                    }
                }
            }
        });
    }

    filterTopFiveIndustries() {
        this.listTopFiveIndustries = [];
        this.brandService.topFiveInustries.forEach((industry: string) => {
            this.industries.map((item: any, index: number) => {
                if (industry.toLowerCase() == item.name.toLowerCase()) {
                    this.listTopFiveIndustries.push(this.industries[index]);
                }
            });
        });
        this.filterTopIndustries();
    }
    filterTopIndustries() {
        this.listTopIndustries = [];
        this.brandService.topEightInustries.forEach((industry: string) => {
            this.industries.map((item: any, index: number) => {
                if (industry.toLowerCase() == item.name.toLowerCase()) {
                    this.listTopIndustries.push(this.industries[index]);
                    this.industries.splice(index, 1);
                }
            });
        });
        this.listTopIndustries.push(...this.industries);
    }
}
