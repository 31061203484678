<!-- Create video Header -->
<header class="header header-1 drop-shadow">
    <div class="container">
        <nav class="navbar navbar-expand-lg" style="">
            <div class="d-flex w-100">
                <div class="col d-flex align-items-center">
                    <div class="back_cta">
                        <a href="javascript:void(0)" (click)="back()">
                            <img src="../assets/images/back-arrow.svg" alt="back-arrow">
                        </a>
                    </div>
                    <h3 class="text-gray-1 mb-0" *ngIf="!this.videoManger.isEditMode">
                        {{this.videoManger?.selectedVideo?.title ? this.videoManger?.selectedVideo?.title : "Create a
                        Video"}}</h3>

                    <h5 class="text-gray-1 pt-2"
                        *ngIf="this.videoManger.isEditMode && !this.videoManger.selectedVideo.edit_title">
                        {{this.videoManger.selectedVideo.title}}
                    </h5>
                    <span class="ms-2 text-xs bg-gray-3 text-white labelTag"
                        *ngIf="this.videoManger.isEditMode && !this.videoManger.selectedVideo.edit_title">{{this.videoManger.selectedVideo.duration}}s</span>

                    <input *ngIf="this.videoManger.isEditMode && this.videoManger.selectedVideo.edit_title"
                        #editTitleField class="p-1 m-0 text-gray-2 border-0 pe-3 caret-orange" maxlength="90"
                        [(ngModel)]="this.videoManger.selectedVideo.title_temp" (keydown)="updateTitle($event.keyCode)"
                        [value]="this.videoManger.selectedVideo.title" />

                    <span *ngIf="this.videoManger.isEditMode"
                        [ngClass]="{'active': this.videoManger.selectedVideo.edit_title}"
                        class="edit-title ms-2 pointer" (click)="editTitle(this.videoManger.selectedVideo)">
                        <a matTooltip="Rename Video" matTooltipClass="custom-tooltip" matTooltipPosition="right">
                            <img src="../../../assets/images/svg/edit-video.svg"></a>

                    </span>
                </div>
                <div class="col text-end" *ngIf="!this.videoManger?.previewMode || this.videoManger?.isVideoGenerated">
                    <a class="text-gray-6 text-sm me-4" href="javascript:void(0)" (click)="cancel()"
                        *ngIf="this.showCancel">Cancel</a>
                    <a (click)="previous()" class="text-gray-6 text-sm me-4 " *ngIf="this.showPrevious">Previous</a>
                    <a (click)="next()" class="btn btn-md btn-secondary btn-round-6 " *ngIf="this.showNext">Next</a>
                    <a (click)="next()" class="btn btn-md btn-secondary btn-round-6 "
                        *ngIf="this.showPreview">Preview</a>
                    <a (click)="save()" class="btn btn-md btn-secondary btn-round-6 "
                        *ngIf="this.showSave">{{this.saveButtonTitle}}</a>
                </div>
            </div>

        </nav>
    </div>
</header>

<section class="accountSec main-body bg-gray-7 h-100 w-100 width-72">
    <div class="container"
        [ngClass]="(!this.videoManger?.previewMode == true && this.videoManger.isEditMode == true)? 'pr-90':'pr-30'">
        <div class="row align-items-center">
            <div class="col-md-6">
                <app-create-video-breadcrumb *ngIf="!this.isTimeLineView && !this.videoManger.isEditMode"
                    [enableMenu]="this.videoManger.selectedVideo != undefined"></app-create-video-breadcrumb>
            </div>
            <!-- <div class="col-md-6" style="padding-right: 60px;" *ngIf="!this.videoManger.previewMode && !this.videoManger.isEditMode">
                <div class="justify-content-end d-flex " >
                    <div class="pt-0 form-check form-switch custom-switch d-flex align-items-center toggle_switch">
                        <input checked class="form-check-input mx-2 order-2 form-check-input-active-both"
                            type="checkbox" id="MyMediaLibrary"
                            (change)="changeview($event)"
                             >
                        <label class="text-md order-1" for="MyMediaLibrary">Timeline</label>
                        <label class="text-md order-3 text-gray-6" for="MyMediaLibrary">Wizard</label>
                    </div>
                </div>
            </div> -->
            <div style="height: 30px; width: 100%"
                *ngIf="this.videoManger?.isEditMode && !this.videoManger?.previewMode"></div>
        </div>
        <router-outlet></router-outlet>
    </div>
</section>