import { duration } from 'src/app/models/video';
import { SearchData } from 'src/app/models/admin';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ApiURL } from 'src/app/conf/api-path';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MediaEditorPopupComponent } from '../shared/media-editor-popup/media-editor-popup.component';

@Injectable({
  providedIn: "root",
})

export class VideoDataStore {
 constructor(private http: HttpClient, private dialog: MatDialog) {

 }
 public selectedTemplate = undefined;
 public clearGenerationInterval = false;
 public editVideoTextOverLayFieldsLength = [];
 public editVideoTextOverLayFieldsDefaultLength = 40;
 public sapVideo = new SAPVideo();

 lVideoFps = [
  { name: '24 ', value: 24, checked: false, isEnabled: true },
  { name: '25 ', value: 25, checked: false, isEnabled: true },
  { name: '29.97 ', value: 29.97, checked: false, isEnabled: true },
  { name: '30 ', value: 30, checked: false, isEnabled: true },
  { name: '60 ', value: 60, checked: false, isEnabled: true },
  { name: '23.97 ', value: 23.97, checked: false, isEnabled: true },
  { name: '', value: null, checked: false, isEnabled: true },
];
 getSapSecondaryLogoFile(){
  return { obj : this.sapVideo.secondayLogoObject, fPath : this.sapVideo.secondaryLogoPath };
 }
  setSapSecondaryLogoFile(obj, path){
    this.sapVideo.secondayLogoObject = obj;
    this.sapVideo.secondaryLogoPath = path;
  }

  initSapVideoObject(){
    this.sapVideo = new SAPVideo();
  }

  openMediaEditorComponent(media : any, options : any, callback : any){
    if(media.dummy){
    media.url  = "https://prontodev230912.blob.core.windows.net/1000874121157-dev/True Meridian/brands/645f7d72186fe005b70d223b/videos/Video_voice_over_6_16x9_d2f9dfae3b.mp4?se=2077-07-24T22%3A52%3A40Z&sp=r&sv=2023-08-03&sr=b&sig=n5vW/nYdhanbMlh0XeyuQoyFo9MylXr4r3tCHNqr3kw%3D";

      media.title = "titlevideoapth";
      media.type = 'video';
      media.start_point = 2;
      media.startFrom = 2;
      media.duration = 133;
    }
    if(!media.start_point){
      media.start_point = 0;
    }
    if(!media.type){
      media.type = 'video';
    }
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.height = '400px';
    dialogConfig.panelClass = 'media-editing-tool-component';
    dialogConfig.closeOnNavigation = true;

    dialogConfig.data = {
        heading: 'Edit Video',
        media : media
    };


        let dialogModal = this.dialog.open(MediaEditorPopupComponent, dialogConfig);

        dialogModal.afterClosed().subscribe((data: any) => {
            callback(data);
            if (data?.status == 'success' || data?.type == 'success') {
            } else {
            }
        });
  }

  getTimeRulerBreakPoints(duration, maxPart){
      let timeScaleRuler = [];
      let nCurrentBreakPoint: number = 0;
      const nBreakPoint: number = duration / maxPart;
      for (let nBreakPointIndex = 0; nBreakPointIndex <= maxPart; nBreakPointIndex++) {
        let lBreakPointPartition: string[] = [];
        const nNextbreakPoint: number = nCurrentBreakPoint + nBreakPoint;
        if (nBreakPointIndex !== maxPart) {
          for (
            let nBreakPointPartitionIndex = nCurrentBreakPoint;
            nBreakPointPartitionIndex < Math.floor(nNextbreakPoint);
            nBreakPointPartitionIndex++
          ) {
            lBreakPointPartition.push("span" + nBreakPointPartitionIndex);
          }
        }
        timeScaleRuler.push({
          breakpoint: Math.round(nCurrentBreakPoint),
          breakPointPartition: lBreakPointPartition,
        });
        nCurrentBreakPoint += nBreakPoint;
      }

      return timeScaleRuler;
  }

}

export class SAPVideo {
  secondaryLogoPath? : string;
  secondaryLogoSignURLPath? : string;
  secondayLogoObject? : any;
}