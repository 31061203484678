import { Component, Input, OnInit } from '@angular/core';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { VoiceOverService } from '../../services/voice-over.service';
import { VoiceDataStore } from 'src/app/stores/voice.store';

@Component({
    selector: 'app-voice-over-single-item',
    templateUrl: './voice-over-single-item.component.html',
    styleUrls: ['./voice-over-single-item.component.scss'],
})
export class VoiceOverSingleItemComponent implements OnInit {
    @Input() voice: any;
    @Input() showPlayer: boolean = true;
    selectedVoices: any[] = [];
    commingVoice = {};
    favoriteVoices: any;
    voiceDuration: number;
    constructor(public voiceService: VoiceOverService, public pSetting: ProntoSettingService, public voiceStore: VoiceDataStore) {}

    ngOnInit(): void {
        // this.voiceStore.confirmSelectedVoice.subscribe((val: any) => {
        //     if (val == true) {
        //       if(this.voice._id.$oid == this.voiceStore.lastSelectedVoiceId._id.$oid){
        //         this.voice.selected = !this.voice.selected;
        //         this.voiceService.addNewItemInSelectedList(this.voice);
        //       }
        //     }
        // });
    }

    addToFavorite(voice: any) {
        this.voiceService.addNewItemInFavoriteList(voice);
    }

    voiceSelectionConfirmationBox(voice) {
        if (!voice.selected) {
            this.voiceStore.lastSelectedVoiceId = voice;
            if (
                voice.license == 'essential' &&
                this.voiceStore.selectedVoiceOverList.essentials.voices.length < this.voiceStore.limitEssential
            ) {
                this.voiceStore.voiceConfirmationPopUp(true);
            } else if (voice.license == 'premium' && this.voiceStore.allowPremium) {
                this.voiceStore.voiceConfirmationPopUp(true);
            } else if (
                voice.license == 'essential' &&
                this.voiceStore.selectedVoiceOverList.essentials.voices.length == this.voiceStore.limitEssential
            ) {
                this.voiceStore.voiceConfirmationPopUp(false);
            }
        }
    }
    isAudioPlaying = false;
    timeoutAudioPlayer = undefined;
    activePlayer: any = undefined;
    playingAudioObj = undefined;
    playingAudioCurrentTime = 0;
    playerInterval(i) {
        let __this = this;
        let rangEle = document.querySelector('#range-voice-' + i);
        if (this.activePlayer) {
            this.timeoutAudioPlayer = setInterval(() => {
                if (!__this.isAudioPlaying || __this.playingAudioObj.duration <= __this.playingAudioObj.current_time) {
                    clearInterval(this.timeoutAudioPlayer);
                } else {
                    // rangEle.setAttribute("value", __this.activePlayer.currentTime+"");
                    __this.playingAudioObj.current_time = __this.activePlayer.currentTime;
                    __this.playingAudioCurrentTime = __this.activePlayer.currentTime;
                    // __this.playingAudioCurrentTime = (__this.playingAudioCurrentTime * 12)
                }
            }, 5);
        }
    }

    pauseAllAudioSounds() {
        // console.log(this.voiceStore.voiceOverList);
        let audioTags = document.querySelectorAll('audio');
        console.log("audioTags ", audioTags);
        audioTags.forEach((player: any) => {
            if(player.play){
                player.pause();
            }
        });
        // this.voiceStore.voiceOverList.map((voiceList: any, index) => {
        //     console.log(voiceList.voices);
        //     voiceList.voices.map((item: any, index) => {
        //         let audioEle = <HTMLAudioElement>document.querySelector('#audio-player-' + item._id.$oid);
        //         if (audioEle) {
        //             audioEle.pause();
        //             clearInterval(this.timeoutAudioPlayer);
        //         }
        //     });
        // });
    }
    async playAudio(index, obj) {
        // if(this.playingAudioObj){
        //   this.playingAudioObj.current_time = 0;
        // }
        await this.getDuration(obj.speech_styles[0].sample_voice).then(function (length: any) {
            console.log('I got length ' + length);
            obj.duration = length;
        });

        if (
            this.playingAudioObj &&
            obj.speech_styles[0].sample_voice != this.playingAudioObj.speech_styles[0].sample_voice &&
            this.activePlayer
        ) {
            this.activePlayer.pause();
            this.activePlayer.current_time = 0;
        }
        this.pauseAllAudioSounds();
        // this.activePlayer.pause();
        this.playingAudioObj = obj;

        let shouldPlay = !obj.play;
        obj.play = !obj.play;
        let audioEle = <HTMLAudioElement>document.querySelector('#audio-player-' + index);
        let audioElet = document.querySelectorAll(`#audio-player-${index}`);
        if (!obj.obj && shouldPlay) {
            this.activePlayer = undefined;
            this.isAudioPlaying = false;
            this.playingAudioObj.current_time = 0;
            audioEle.addEventListener("pause", ()=>{
                console.log("Pause event called");
                this.voice.play = false;
                clearInterval(this.timeoutAudioPlayer);
            });
            if (audioEle) {
                this.activePlayer = audioEle;
                audioEle.play();
                console.log(' play audio ');
                this.isAudioPlaying = true;

                this.playerInterval(index);
            }
        } else {
            if (audioEle) {
                clearInterval(this.timeoutAudioPlayer);
                audioEle.pause();
                this.activePlayer = undefined;
                this.isAudioPlaying = false;
                this.playingAudioObj = undefined;
            }
        }
    }

    getDuration(src) {
        return new Promise(function (resolve) {
            var audio = new Audio();
            $(audio).on('loadedmetadata', function () {
                resolve(audio.duration);
            });
            audio.src = src;
        });
    }
}
