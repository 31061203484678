import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandService } from '../../myAccount/manage-brand/service/BrandService';
import { ConfigManagerService } from './../../../Managers/config-manager.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { ErrorService } from './../../../services/error.service';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
var Filter = require('bad-words'),
    filter = new Filter();
@Component({
    selector: 'app-create-account-company',
    templateUrl: './create-account-company.component.html',
    styleUrls: ['./create-account-company.component.scss'],
})
export class CreateAccountCompanyComponent implements OnInit {
    fUserProfileForm: FormGroup;
    sSessionId: string;
    sUserEmail: any;
    sUsername: string;
    industries: any = [];
    sNewIndustry: string;
    sNewlyAddedIndustry: string;
    bDisplayErrorMsg: boolean = false;
    sErrorMessage: string;
    bloadIndustries: boolean = false;
    limitIndustryList = 8;

    sSelectedIndustry: any;
    industryList: [];
    totalIndustries = 0;
    listTopFiveIndustries = [];
    listTopIndustries = [];
    otherActive = false;
    bDisabledButtons = true;
    userEmail: string;
    userSession: string;
    constructor(
        private oSessionService: cSessionService,
        public configManager: ConfigManagerService,
        public onboardingManager: OnboardingManagerService,
        private oFormBuilder: FormBuilder,
        private oWebSocketService: cWebSocketService,
        private oSeesionService: cSessionService,
        private oRouter: Router,
        public errorService: ErrorService,
        private routerParams: ActivatedRoute,
        private brandService: BrandService,
    ) {}

    ngOnInit(): void {
        this.userEmail = this.oSessionService.cSessionService_GetUserEmail();
        this.userSession = this.oSessionService.SessionService_GetSessionId();
        // const emailAddress = this.routerParams.snapshot?.queryParams?.email;
        const emailAddress = this.userEmail;
        if (!emailAddress || emailAddress == '') {
            this.oRouter.navigate(['login']);
        }
        if (!this.onboardingManager.profile) {
            this.oRouter.navigate(['login']);
        }
        this.industries;
        this.CreateAccountCompanyComponent_InitializeUserProfileForm();
        this.mapIconToIndustires();
        this.CreateAccountCompanyComponent_InitializeJqueryCode();
        this.getIndustry();
    }
    CreateAccountCompanyComponent_InitializeUserProfileForm() {
        this.fUserProfileForm = this.oFormBuilder.group({
            company_name: [''],
            url: [''],
            business_type: [''],
            vertical: [''],
            address: this.oFormBuilder.group({
                line1: '',
                line2: '',
                city: '',
                state: '',
                country: '',
                postal_code: '',
            }),
            phone: [''],
            email: this.userEmail,
            color: '',
            logo_file: '',
            greeting: '',
            mood: '',
            location: [''],
            name: [''],
            is_subscribed_to_email: [0],
        });
    }

    save() {
        if (this.fUserProfileForm.valid) {
            let profile = {
                business_name: this.fUserProfileForm.get('company_name').value,
                // profile.company_name = this.fUserProfileForm.get('company_name').value;
                location: this.fUserProfileForm.get('location').value,
                url: this.fUserProfileForm.get('url').value,
                _id: this.onboardingManager.profile.email,
                email: this.onboardingManager.profile.email,
                session_id: this.onboardingManager.profile.session_id,
                ignore_plan: true,
                industry: this.sSelectedIndustry,
            };

            this.onboardingManager
                .updateBusinessDetail(profile, true)
                .then((_) => {
                    console.log(_);
                    this.navigatetodashboard(this.onboardingManager.profile.video_counts);
                    // if (this.sSelectedIndustry === this.sNewlyAddedIndustry) {
                    //     // this.oRouter.navigateByUrl("/");
                    //     this.oRouter.navigateByUrl('/dashboard/myvideos');
                    //     this.navigatetodashboard( this.onboardingManager.profile.video_counts);
                    // } else {
                    //     this.navigatetodashboard( this.onboardingManager.profile.video_counts);
                    //     //
                    // }
                })
                .catch((err) => {
                    this.errorService.handleError(err.errorMessage, err.errorType, true);
                    console.log(err);

                    this.sErrorMessage = JSON.parse(err['errorMessage']);
                    this.bDisplayErrorMsg = true;
                    setTimeout(() => {
                        this.bDisplayErrorMsg = false;
                    }, 5000);
                });
        } else {
            this.fUserProfileForm.markAllAsTouched();
        }
    }

    navigateUserToDashBoard() {

        this.navigatetodashboard(this.onboardingManager.profile.video_counts);
    }
    public navigatetodashboard(count) {
        if (this.configManager.metaInfo.stripe_enabled) {
            this.oRouter.navigateByUrl('/create-account/select-plan');
        } else {
            if (count > 0) {
                this.oRouter.navigateByUrl('/dashboard/myvideos');
            } else {
                this.oRouter.navigateByUrl('/dashboard/first-time-user');
            }
        }
    }

    getIndustry() {
        let __this = this;
        this.brandService.getIndustryList().then((res: any) => {
            this.industryList = res['result'];
            this.industries = res['result'];
            this.totalIndustries = this.industries.length;
            this.filterTopFiveIndustries();

            if (this.industries.length) {
                for (let i = 5; i < this.industries.length; i++) {
                    const element = this.industries[i];
                    if (element.name === this.sSelectedIndustry) {
                        this.otherActive = true;
                    }
                }
            }
        });
    }

    filterTopFiveIndustries() {
        this.listTopFiveIndustries = [];
        this.brandService.topFiveInustries.forEach((industry: string) => {
            this.industries.map((item: any, index: number) => {
                if (industry.toLowerCase() == item.name.toLowerCase()) {
                    this.listTopFiveIndustries.push(this.industries[index]);
                }
            });
        });
        this.filterTopIndustries();
    }
    filterTopIndustries() {
        this.listTopIndustries = [];
        this.brandService.topEightInustries.forEach((industry: string) => {
            this.industries.map((item: any, index: number) => {
                if (industry.toLowerCase() == item.name.toLowerCase()) {
                    this.listTopIndustries.push(this.industries[index]);
                    this.industries.splice(index, 1);
                }
            });
        });
        this.listTopIndustries.push(...this.industries);
    }

    CreateAccountCompanyComponent_AddNewIndustry() {
        this.sNewIndustry = filter.clean(this.sNewIndustry);
        if (!this.sNewIndustry.includes('*')) {
            this.industries.push({ name: this.sNewIndustry, path: '', viewBox: '0 0 33 32' });
        }
        this.sNewlyAddedIndustry = this.sNewIndustry;
        this.sNewIndustry = '';
    }
    CreateAccountCompanyComponent_PatchIndustryValue(industry: string) {
        this.sSelectedIndustry = industry;
        this.otherActive = false;
        if (this.industries.length) {
            for (let i = 5; i < this.industries.length; i++) {
                const element = this.industries[i];
                if (element.name === this.sSelectedIndustry) {
                    this.otherActive = true;
                }
            }
        }
        console.log('CreateAccountCompanyComponent_PatchIndustryValue : Selected Industry ==>', this.sSelectedIndustry);
    }
    showAll() {
        this.bloadIndustries = true;
        this.limitIndustryList = this.totalIndustries;
    }

    async mapIconToIndustires() {
        if (!this.configManager.metaInfo) {
            await this.configManager.getMetaInfo();
        }
        const oResIndustries = this.configManager.metaInfo.category_map;
        for (const key in oResIndustries) {
            if (Object.prototype.hasOwnProperty.call(oResIndustries, key)) {
                if (key !== 'Entertainment' || 'Agriculture' || 'Manufacturing' || 'Non-Profit') {
                    this.industries.push({ name: key, path: '', viewBox: '0 0 33 32' });
                }
            }
        }

        //  this.industries.forEach(outerElement =>
        //   {
        //     this.lIndustriesSaved.forEach(innerElement =>
        //     {
        //       if(innerElement.name===outerElement.name)
        //       {
        //         outerElement.path = innerElement.path;
        //         outerElement.viewBox = innerElement.viewBox;
        //       }
        //     });
        //   });
        console.log('CreateAccountCompanyComponent_ Updated Industries == ', this.industries);
    }

    CreateAccountCompanyComponent_InitializeJqueryCode() {
        let chatScript = document.createElement('script');
        chatScript.type = 'text/javascript';
        chatScript.async = true;
        chatScript.text = `
    $(document).ready(function () {
      $("#sidebar").mCustomScrollbar({
          theme: "minimal"
      });

      $('#dismiss, .overlay, #close-industry-panel').on('click', function () {
          $('#sidebar').removeClass('active');
          $('.overlay').removeClass('active');
      });

      $('#sidebarCollapse').on('click', function () {
          $('#sidebar').addClass('active');
          $('.overlay').addClass('active');
          $('.collapse.in').toggleClass('in');
          $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });
  });`;
        document.body.appendChild(chatScript);
    }
    visibleBrand = false;
    // placementBrand: NzDrawerPlacement = 'right';
    openBrand(): void {
        // if (this.bEditButton == false) {
            this.visibleBrand = true;
            this.otherActive = true;
        // }
    }
    closeBrand(): void {
        this.visibleBrand = false;
        // this.otherActive = false;
    }
}
