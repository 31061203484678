import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { ConfigManagerService } from 'src/app/Managers/config-manager.service';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { ModifyVideoManagerService } from 'src/app/Managers/modify-video-manager.service';
import { template, templatesCategory } from 'src/app/models/template';
import {ModifyVideo} from '../../../../models/modify-video';
import { mediaFile } from '../../../../models/media';
import { MediaManagerService } from 'src/app/Managers/media-manager.service';
import { cSessionService } from 'src/app/services/session.service';
import { ErrorService } from 'src/app/services/error.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadFileDialogComponent } from 'src/app/shared/components/upload-file-dialog/upload-file-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VideoDataStore } from 'src/app/stores/video.store';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';

@Component({
  selector: 'app-mv-looknfeel',
  templateUrl: './mv-looknfeel.component.html',
  styleUrls: ['./mv-looknfeel.component.scss']
})
export class MvLooknfeelComponent implements OnInit, OnDestroy {
  @ViewChild('previewTemplate') previewTemplate: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('AddInputTagElement') AddInputTagElement: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();

  bDisplayAddInputField: boolean = false;
  lKey_terms: any = [];
  oUploadedLogo: any ;
  secondaryLogoFile: any ;
  nUploadedLogoSize: number = 0;
  bDisplayUploadLogoSection: boolean = false;
  inputValue: string = "";
  public selectedCategory: templatesCategory;
  public selectedTemplate: template;
  public templates:template[]=[];

  sDisplayResponseErrorMessage: string;
  bDisplayErrorMessage: boolean = false;
  sUploadedLogoName: string = "No file choosen.";
  sSessionID: string = "";
  sUserEmail: string = "";
  showLogoUploadModal = false;
  public formGroup : FormGroup;
  applyPrimaryLogoAtVideoStart = true;
  isLogoSelected = true;
  countForlouderBrand = 0;
  constructor(public modifyVideoObj : ModifyVideo,public modifyVideoManager: ModifyVideoManagerService, private router: Router,
    public videoManger: CreateVideoManagerService,
    public configManager: ConfigManagerService,
    private mediaManger:MediaManagerService,
    private oSessionService: cSessionService,
    private errorMessage : ErrorService,
    private dialog: MatDialog,
    private cd : ChangeDetectorRef,
    public lookFeelForm: FormBuilder,
    public videoStore : VideoDataStore,
    public onBoardingManager: OnboardingManagerService,
    ) {


    this.formGroup = lookFeelForm.group({
      otherLogoRadio: ['Yes', Validators.required]
    });
    }

  sub: Subscription;
  sub2:Subscription;
  ngOnInit() {
    if( this.onBoardingManager.profile.organization_id == "65d5d0c9c4a3359b9fcf9e9f"){
        this.countForlouderBrand = 1;
    }
    this.isLogoSelected = true;
    this.sSessionID = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();


    // this.lKey_terms = this.modifyVideoObj.keyword;
    // this.oUploadedLogo = this.modifyVideoObj.logo;
    // console.log("value fetch");
    // console.log(this.modifyVideoObj, this.modifyVideoObj.name, this.modifyVideoObj.videoObj,
    //  this.modifyVideoObj.keyword, this.modifyVideoObj.logo);

    this.sub2 = this.videoManger.previousSubmit.subscribe(emit => {
      // console.log("Move to over View ...");
    // this.modifyVideoObj.keyword = this.lKey_terms;
    // this.modifyVideoObj.logo = this.oUploadedLogo;
      this.router.navigateByUrl('/mv/overview');
    });
    console.log(" look and feel loaded ", this.videoManger.selectedVideo);
    if(this.videoManger.selectedVideo.sap_secondary_logo_signed_url){
      this.videoManger.selectedVideo.sap_secondary_logo = this.videoManger.selectedVideo.sap_secondary_logo;
      this.applyPrimaryLogoAtVideoStart = false;
      // this.se this.videoManger.selectedVideo.sap_secondary_logo_signed_url
    }
    this.sub = this.videoManger.submitForm.subscribe(emit => {
      console.log(" >>> SUBMIT CALLED ...");

      let logoImg = this.oUploadedLogo ? this.oUploadedLogo.visual_path : undefined;
      let secondaryLogo = (this.secondaryLogoFile && this.secondaryLogoFile.visual_path);
      if(!logoImg && !secondaryLogo){
        secondaryLogo = this.videoManger.selectedVideo.sap_secondary_logo ? this.videoManger.selectedVideo.sap_secondary_logo : undefined;
        logoImg = (this.videoManger.selectedVideo.logo_img && this.videoManger.selectedVideo.logo_img);
      }
      if(!logoImg && !secondaryLogo){
        this.isLogoSelected = false;
        console.log('dont redirect ...');
        this.router.navigateByUrl('/mv/looknfeel');
      } else {
        this.isLogoSelected = true;
        this.submitForm(emit);
      }
    });

    this.lKey_terms = this.videoManger.selectedVideo?.key_terms;

    if (this.videoManger.selectedVideo.logo_img) {
      this.bDisplayUploadLogoSection = true;
      this.oUploadedLogo = { url : this.videoManger.selectedVideo.logo_img_url };
      this.isLogoSelected = true;
    }

  }

  ngOnDestroy() {
    if(this.sub2)
    this.sub2.unsubscribe();
    if(this.sub)
    this.sub.unsubscribe();
    if (this.videoManger.isEditMode) {
      this.updateVideoLocally();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  submitForm(submitFrom: string) {
    // this.modifyVideoObj.keyword = this.lKey_terms;
    // this.modifyVideoObj.logo = this.oUploadedLogo;

    this.updateVideoLocally();
    this.videoManger.selectedVideo.step = 'looknfeel';
    this.videoManger.createVideo(this.videoManger.selectedVideo).then(res => {
      if (res) {


        if (!this.videoManger.isEditMode) {
          this.router.navigateByUrl('/mv/content');
        } else {
          this.router.navigateByUrl("/mv/preview");
        }
      }
    }
    ).catch(err => { });
//,
  }
  private updateVideoLocally() {

    this.videoManger.selectedVideo.key_terms = this.lKey_terms;
    this.videoManger.selectedVideo.template_name = "SAP";

    this.videoManger.selectedVideo.logo_img = this.CreateVideoLooknFeelComponent_ReturnLogo();
    if(this.secondaryLogoFile && this.secondaryLogoFile.visual_path){
      this.videoManger.selectedVideo.sap_secondary_logo = this.secondaryLogoFile.visual_path;
      this.videoManger.selectedVideo.sap_secondary_logo_signed_url = this.secondaryLogoFile.url;
    } else {
      this.videoManger.selectedVideo.sap_secondary_logo = this.videoManger.selectedVideo.logo_img;
      if(this.oUploadedLogo && this.oUploadedLogo.url){
        this.videoManger.selectedVideo.sap_secondary_logo_signed_url = this.oUploadedLogo.url;
      }
    }
    this.videoManger.selectedVideo.logo_type = 'own';
    if(this.videoManger.selectedVideo.sap_secondary_logo == undefined || this.videoManger.selectedVideo.sap_secondary_logo == ''){
      delete this.videoManger.selectedVideo.sap_secondary_logo;
    }
    if(this.videoManger.selectedVideo.logo_img == undefined || this.videoManger.selectedVideo.logo_img == ''){
      if(this.videoManger.selectedVideo.sap_secondary_logo && this.videoManger.selectedVideo.sap_secondary_logo != ''){
        this.videoManger.selectedVideo.logo_img = this.videoManger.selectedVideo.sap_secondary_logo;
      } else {
        delete this.videoManger.selectedVideo.logo_img;
      }
    }
    if(!this.videoManger.selectedVideo.logo_img && !this.videoManger.selectedVideo.sap_secondary_logo){
      delete this.videoManger.selectedVideo.logo_type;
    }
   }

   setSapSecondaryLogoFile(){
    this.videoStore.setSapSecondaryLogoFile(this.secondaryLogoFile, this.videoManger.selectedVideo.sap_secondary_logo)
   }
   CreateVideoLooknFeelComponent_ReturnLogo() {
    let logo_path;
      logo_path = this.oUploadedLogo? this.oUploadedLogo.visual_path:"";
    return logo_path;
  }
  selectTemplate(template: template) {

    this.selectedTemplate = template;

    // this.videoManger.changeTemplate(template);
    // this.bSecondLogo = this.selectedTemplate.second_logo;
  }


  CreateVideoLooknFeelComponent_FocusoutFromInput() {
    if (this.inputValue !== "") {
      if(!this.lKey_terms)
      this.lKey_terms =[];
      this.lKey_terms.push(this.inputValue);

    }
    setTimeout(() => { this.bDisplayAddInputField = false; }, 100);
  }
  AddTagInputField() {
      this.bDisplayAddInputField = false;
      if (!this.lKey_terms) this.lKey_terms = [];

          let tagIndex = this.lKey_terms.findIndex( (item : string ) => {
           return (item.toLowerCase() == this.inputValue.trim().toLowerCase())
          });

          if(tagIndex == -1 && this.inputValue.trim() != '') {

              this.lKey_terms.push(this.inputValue.trim());
          }
          setTimeout(() => {
              this.bDisplayAddInputField = false;
          }, 100);

          this.inputValue = '';
  }
  addTagOnKeyEnter(keycode) {
      if ((keycode == 13 && this.inputValue != '') || (keycode == 188 && this.inputValue != '')) {
          this.AddTagInputField();
      }
  }
  CreateVideoLooknFeelComponent_RemoveTagListElements(index) {
    this.lKey_terms.splice(index, 1)
  }
  CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInput() {
    this.inputValue = "";
    setTimeout(() => this.AddInputTagElement.nativeElement.focus());
    this.bDisplayAddInputField = true;
  }
  CreateVideoLooknFeelComponent_GetInputValue(event) {
    this.inputValue = event.target.value;

  }


  CreateVideoLooknFeelComponent_ReturnFileSize(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  PreviewTemplate() {
    this.previewTemplate.nativeElement.play();
  }

  startLogoUploadProcess($event, section = 'main'){

    if(section == 'dragdrop'){
      this.initiateLogoUploadProcess($event);
    } else {
      this.initiateLogoUploadProcess($event.target.files);
    }
  }

  async initiateLogoUploadProcess(files) {
    // let file = event.target.files[0];
    let file = files[0];
    if(file.type && !(file.type.indexOf('jpeg') > -1 || file.type.indexOf('jpg') > -1 || file.type.indexOf('png') > -1)){
      this.showLogoUploadModal = false;
      this.errorMessage.handleError('Only jpeg, jpeg and png files are allowed', 'Logo Upload Failed', true);
    } else {
    this.showLogoUploadModal = true;
    this.nUploadedLogoSize = file.size;
    this.closeModal.nativeElement.click();

    let media = new mediaFile();
    media.file = file;
    media.name = file.name;
      try{
        this.mediaManger.uploadMediaDefaultLibrary(this.sUserEmail,this.sSessionID,media).then(res=>{
          this.showLogoUploadModal = false;
          if(res){
            this.bDisplayUploadLogoSection = true;
            this.isLogoSelected = true;
          }
          this.oUploadedLogo = res;

          this.videoManger.selectedVideo.logo_img_url = this.oUploadedLogo;
          this.sUploadedLogoName = file.name;



        }).catch(error=>{
          this.showLogoUploadModal = false;
          this.oUploadedLogo = null;
          this.sDisplayResponseErrorMessage = error.error.errorMessage;
          this.bDisplayErrorMessage = true;
          this.closeModal.nativeElement.click();
          this.errorMessage.handleError(error.errorMessage ? error.errorMessage : error.error, 'Logo Upload Failed', true);
          setTimeout(() => { this.bDisplayErrorMessage = false; }, 5000);
        });
      } catch(error){
        this.showLogoUploadModal = false;
        this.oUploadedLogo = null;
        this.errorMessage.handleError(error.errorMessage ? error.errorMessage : error.error, 'Logo Upload Failed', true);
      }

    }
  }

  //Update video logo
  openFileUploadModal(uploadFor, heading, type ) {
    const typeUpload = type;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100wv";
    dialogConfig.height = "100vh";
    dialogConfig.panelClass = "pronto-dialog";
    dialogConfig.closeOnNavigation = true;

    dialogConfig.data = {
      uploadFor: uploadFor,
      multipleUpload: false,
      heading: heading,
      allowDuplicate : true,
      component : type
    };

    let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

    dialogModal.afterClosed().subscribe((data: any) => {
      if (data.status == "success") {
        if(type === 'logoUpload') {
          this.bDisplayUploadLogoSection = true;
          this.oUploadedLogo = data.result[0];
          this.videoManger.selectedVideo.logo_img_url = this.oUploadedLogo.url;
          this.sUploadedLogoName = this.oUploadedLogo.name;
          this.isLogoSelected = true;
        } else {
          this.secondaryLogoFile = data.result[0];
          this.videoManger.selectedVideo.sap_secondary_logo = data.result[0].url;
          // this.sUploadedLogoName = data.result[0].name;
        }

        this.cd.detectChanges();
      } else {

      }
    });

     this.mediaManger.replaceMediaFromVisualPopup.pipe(takeUntil(this.destroy$)).subscribe((item : any) =>{
      console.log( "Item here ", item);
      if(item && item.length > 0) {
        if(item.component && item.component === 'logoUpload') {
          console.log("replacing for logoupload");
          this.bDisplayUploadLogoSection = true;
          this.isLogoSelected = true;
          this.oUploadedLogo = item[0];
          this.videoManger.selectedVideo.logo_img_url = this.oUploadedLogo.url;
          this.sUploadedLogoName = this.oUploadedLogo.name ? this.oUploadedLogo.name : this.oUploadedLogo.fpath;
        } else {
          console.log(" --- we are here after replace ...");
          this.secondaryLogoFile = item[0];
          this.videoManger.selectedVideo.logo_type = 'own';
          this.videoManger.selectedVideo.sap_secondary_logo = item[0].url;
          // this.sUploadedLogoName = item[0].name;
        }
      }
      this.mediaManger.showMediaReplacementPopup = false;
      this.cd.detectChanges();
    })
  }

  togglePrimaryLogoAtVideoStart(val){
    this.applyPrimaryLogoAtVideoStart = val;
  }
}
