import { map } from 'rxjs/operators';
import { VoiceOverService } from './../../services/voice-over.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VoiceDataStore } from './../../../stores/voice.store';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VoiceOverFilterSearchWizardComponent } from '../voice-over-filter-search-wizard/voice-over-filter-search-wizard.component';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';

@Component({
    selector: 'app-browse-voice-over',
    templateUrl: './browse-voice-over.component.html',
    styleUrls: ['./browse-voice-over.component.scss'],
})
export class BrowseVoiceOverComponent implements OnInit {
    @Input() searchAttributes: any[];
    @Output() showFilter: EventEmitter<any> = new EventEmitter();
    @Input() isModel = false;
    @Input() showBrowseVoice = false;
    selectedTab = 'browse';
    loader = false;
    public searchVoice: string = '';
    voiceOverList: any[] = [];
    constructor(public voiceDataStore: VoiceDataStore,
        public voiceService: VoiceOverService,
        private dialog: MatDialog,
        private onBoarding : OnboardingManagerService) {}

    ngOnInit(): void {
        this.voiceOverList.push({});
        this.voiceOverList.push({});
        this.loader = true;
        if (this.isModel && !this.showBrowseVoice) {
            this.searchVoicesForFilters(this.searchAttributes);
        } else {
            this.updateSelectedTab('browse');
        }
    }

    ngOnDestroy(): void {
        // throw new Error('Method not implemented.');
    }

    updateSelectedTab(option: string) {
        this.selectedTab = option;
        if (this.selectedTab == 'browse') {
            this.getVoices();
        } else if (this.selectedTab == 'selected') {
            this.getSelectedlist();
        } else {
            this.getFavoritlist();
        }
    }

    openFilterSearchModal() {
        console.log(' is modal ', this.isModel);
        if (!this.isModel) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            dialogConfig.width = '100wv';
            dialogConfig.height = '100vh';
            dialogConfig.minWidth = '1000';
            dialogConfig.panelClass = 'pronto-dialog';
            dialogConfig.closeOnNavigation = true;

            dialogConfig.data = {};

            let showUploadModal = true;
            if (!dialogConfig.data.mediaLibraryId) {
                delete dialogConfig.data.mediaLibraryId;
            }
            if (showUploadModal) {
                let dialogModal = this.dialog.open(VoiceOverFilterSearchWizardComponent, dialogConfig);

                dialogModal.afterClosed().subscribe((data: any) => {});
            }
        } else {
            this.showFilter.emit();
        }
    }

    searchVoicesForFilters(filter) {
        this.voiceService.searchVoiceByFilters(filter).then((res) => {
            console.log('Search Data here .... ');
            this.loader = false;
            if (this.voiceDataStore?.searchedVoiceList?.essentials.voices) {
                this.voiceOverList[0] = this.voiceDataStore?.searchedVoiceList?.essentials;
            }
            if (this.voiceDataStore?.searchedVoiceList?.premium.voices) {
                this.voiceOverList[1] = this.voiceDataStore?.searchedVoiceList?.premium;
            }
        });
    }
    getVoices() {
        this.loader = true;
        this.voiceOverList = [];
        this.voiceService.browseVoiceOver([], this.selectedTab).then((result: any) => {
            this.loader = false;
            if (result) {
                if (result.data.essentials) {
                    this.voiceOverList[0] = result.data.essentials;
                }
                if (result.data.premium) {
                    this.voiceOverList[1] = result.data.premium;
                }
                console.log(this.voiceOverList);
            }
        });
    }
    getFavoritlist() {
        this.loader = true;
        this.voiceOverList = [];
        this.voiceService.getFavoriteVoiceList().then((result: any) => {
            if(result && result.length == 2) {
                result[1].disabled = this.onBoarding?.profile?.plan == 'enterprise' ? true : false;
            }
           this.loader = false;
            if (result.data.essentials) {
                this.voiceOverList[0] = result.data.essentials;
            }
            if (result.data.premium) {
                this.voiceOverList[1] = result.data.premium;
            }
        });
    }
    getSelectedlist() {
        this.voiceOverList = [];
        this.loader = true;
        this.voiceService.getSelectedVoiceList().then((result: any) => {
            if(result && result.length == 2) {
                result[1].disabled = this.onBoarding?.profile?.plan == 'enterprise' ? true : false;
            }
            this.loader = false;
            if (result.data.essentials) {
                this.voiceOverList[0] = result.data.essentials;
            }
            if (result.data.premium) {
                this.voiceOverList[1] = result.data.premium;
            }
        });
    }
    clearSearch() {
        this.searchVoice = '';
    }
}
