import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorService } from 'src/app/services/error.service';


@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})

export class VerifyEmailComponent implements OnInit {
  sUserEmail: any;
  code: any;
  errorMsg =""
  sentSuccessfull = false;

  fResetPasswordForm: FormGroup;
  showEmailInput = false;
  constructor(private oActivatedRoute: ActivatedRoute, private oRouter: Router, private onboardingManager: OnboardingManagerService,
    private oFormBuilder: FormBuilder, public errorService: ErrorService) {
      const verifyEmailResult = this.oActivatedRoute.snapshot.queryParams;

    this.sUserEmail = verifyEmailResult.email;
    this.code = verifyEmailResult.code;

    this.VerifyEmailComponent_VerifyUserEmail()
    this.ResetPasswordComponent_InitializeResetPasswordForm();

     }

  ngOnInit(): void {

  }
   ResetPasswordComponent_InitializeResetPasswordForm() {
    const emailAddress = this.oActivatedRoute.snapshot?.queryParams?.email;
    const sEmailRejex = /(^[\w-\.]+@([\w-]+\.)+[\w-]{2,14}$)/;
    this.fResetPasswordForm = this.oFormBuilder?.group({
      email: [
        (emailAddress) ? emailAddress : '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(sEmailRejex)
        ]
      ],
    });
  }
  VerifyEmailComponent_VerifyUserEmail()
  {

   this.onboardingManager.verifyEmail(this.sUserEmail ,this.code).then(res=>{


   this.oRouter.navigateByUrl('/login')


   },(error)=>
   {
    console.log(error);
    this.errorMsg =  error.error.errorMessage;
   }
   )
  }
  reVerifyEmail(){
    this.onboardingManager.reVerifyEmail(this.fResetPasswordForm.get("email").value).then(res => {
      if(res){
        this.sentSuccessfull = true;
      }
    }, (error) => {
      console.log(error);
      this.errorService.handleError(error.errorMessage, error.errorType, true);
    }
    )
  }

}
