import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { filter } from 'rxjs/operators';
import { template } from 'src/app/models/template';
import { VideoDataStore } from 'src/app/stores/video.store';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ITimeUpdateEvent, NgWaveformComponent, IRegionPositions } from 'ng-waveform';
import { Router } from '@angular/router';
import { cWebSocketService } from './../../../../services/websocket.service';
import { cSessionService } from './../../../../services/session.service';

import { cDataService } from './../../../../services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as cloneDeep from 'lodash/cloneDeep';
import { cTemplateConfigService } from './../../../../services/template.service';
import { cEditVideoService } from './../../../../services/edit-video.service';
import { Subject, Subscription } from 'rxjs';

import { CreateVideoManagerService } from './../../../../Managers/create-video-manager.service';
import { ConfigManagerService } from './../../../../Managers/config-manager.service';
import { block_config, content, video } from './../../../../models/video';

import { block_clips, clip } from '../../../../models/video';
import { LoaderService } from 'src/app/services/loader.service';
import { resolve } from 'path';
import { SuccessMessageModalService } from 'src/app/services/success-message-modal.service';
import { BrandService } from 'src/app/components/myAccount/manage-brand/service/BrandService';
import { brand } from 'src/app/models/admin';
import { address } from 'src/app/models/common';
import { VideoService } from 'src/app/services/dataServices/video.service';

declare var $: any;

@Component({
    selector: 'app-video-timeline',
    templateUrl: './video-timeline.component.html',
    styleUrls: ['./video-timeline.component.scss'],
})
export class VideoTimelineComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('videoClips', { static: false }) videoClips: ElementRef;
    @ViewChild('audioWaveform', { static: false })
    audioWaveform: NgWaveformComponent;
    @ViewChild('voiceoverWaveform', { static: false })
    voiceoverWaveform: NgWaveformComponent;
    @ViewChild('width1', { static: false }) width1: ElementRef;
    @ViewChild('videoTimeLine', { static: false }) videoTimeLine: ElementRef;
    @ViewChild('audioPlayerBar', { static: false }) audioPlayerBar: ElementRef;
    eventsSubject: Subject<void> = new Subject<void>();
    audioSource: any = '';
    voiceoverSource: any = '';
    bUploadVoiceOverFile: boolean;
    oUploadedVoiceOverFile: any;
    nVoiceOverFileSize: number = 0;
    sSessionId: any;
    sUserEmail: any;
    sUploadedVoiceOverFileName: string = 'No file choosen.';
    bShowTextToSpeechSection: boolean = true;
    sSelectedVoiceGender: string;
    oUserVoiceOverList: any = [];
    oVoiceOverList: any = [];
    sVoiceName: string;
    nSelectedVoiceIndex: number;
    fVoiceoverForm: FormGroup;
    oAudio = new Audio();
    lCallToAction: any = ['', ''];
    bToggleAudioButton: boolean = true;
    bToggleVoiceoverButton: boolean = true;
    lcta: any = [];
    sVideoId: string;
    // sEditVideoId: string;
    oEditVideoConfiguration: any;
    lBlockConfigurations: block_config[] = [];
    bLetProntoChoose: boolean = true;
    bDisplayMyMediaSection: boolean = false;
    bDisplayUploadSection: boolean = true;
    lFilestoUpload: any = [];
    lUploadedFiles: any = [];
    lUserSelectedVisuals: any = [];
    sResetTextOverLays: string;
    bAudioSidepanel: boolean = false;
    nIncomingVoiceoverFile: any;
    lBlockConfigurationsCopy: block_config[] = [];
    lDurationsList: any = [];
    bResponse: boolean = false;
    oSelectedBlock: content;
    sSelectedBlockId: string;
    lBlockClips: any = [];
    lAllowedFonts: any = [];
    oMusicFile: any;
    bDisplayBlockSidePanel: boolean = false;
    bDisplayBlockTextSidePanel: boolean = false;
    lTemplateCategory: any[];
    oVideoDetails: any;
    oSelectedTemplate: any;
    bShowSelectedTemplate: boolean = false;
    nSelectedTemplateIndex: any;
    oLooknFeelForm: any;
    fLooknFeelForm: FormGroup;
    lKey_terms: any;
    oPrimaryColor: any;
    oFontColor: any;
    sTemplateChange: any;
    oUploadedLogo: any;
    nUploadedLogoSize: any;
    sUploadedLogoName: any;
    lKeyTermsTags: any;
    lChangedTemplate: any = [];
    sDisplayResponseMessage: string;
    bDisplayErrorMessage: boolean = false;
    video: video;
    bVoiceOverSidePanel: boolean = false;
    sSelectedVideoId: any;
    //nSetPollingTimer : any;
    selectedBlock: any;
    lsVideoDuration: any;
    lScaleBreakPoints: any[] = [];
    addCustomClass: string;
    scaleListWidth: number;
    scaleAudioListWidth: number;
    voiceOverLengthContainer: number;
    scaleAudioVoiceOverListWidth: number;
    bIsAudio: boolean;
    session: cSessionService;
    blockClips: block_clips[] = [];
    clips: content[];
    texts: content[];
    videoDuration = 0;
    audioDuration = 0;
    voiceItems = [{ left: 0, top: 0, width: 100, height: 40, ts: [0, 30] }];
    showruler = false;
    resetAudioLength() {}
    constructor(
        private oRouter: Router,
        private oWebSocketService: cWebSocketService,
        private oSessionService: cSessionService,
        private oDataService: cDataService,
        private oFormBuilder: FormBuilder,
        private oTemplateConfigService: cTemplateConfigService,
        private sSessionService: cSessionService,
        private oEditVideoService: cEditVideoService,
        public videoManager: CreateVideoManagerService,
        public config: ConfigManagerService,
        public loader: LoaderService,
        public successMessage: SuccessMessageModalService,
        private brandService: BrandService,
        private videoService: VideoService,
        private videoDataStore: VideoDataStore,
        private settings: ProntoSettingService,
    ) {
        this.session = oSessionService;
        this.numbers = Array(7)
            .fill(0)
            .map((x, i) => i);
    }

    fContentForm: any;
    sub: Subscription;

    async ngOnInit() {
        this.oSessionService.oUploadedLogo = undefined;
        this.videoManager.videoBrand = undefined;
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        // console.log(' LOAD VIDEO EDIT WIZARD ', this.videoManager.isEditMode);
        if (this.videoManager.isEditMode) {
            // console.log("Load video Data ....");
            await this.videoManager.loadSelectedVideoData(this.sUserEmail, this.sSessionId).then((result) => {
                this.loadVoiceSettings();
            });
            this.videoManager.selectedVideo.others_setting = {
                url: this.videoManager.selectedVideo.ci_dict.url != '' ? true : false,
                address: this.videoManager.selectedVideo.ci_dict.address ? true : false,
                phone: this.videoManager.selectedVideo.ci_dict.phone != '' ? true : false,
            };
        }
        if (this.videoManager?.selectedVideo?.brand_id) {
            this.brandService.getBrandProfile(this.videoManager?.selectedVideo?.brand_id).then((brand: brand) => {
                this.videoManager.videoBrand = brand;
            });
        }
        this.sub = this.videoManager.submitForm.subscribe((c) => {
            let cc = this.clips.map((a) => a.source);

            this.videoManager.selectedVideo.template_info.contents
                .filter((c) => c.content === 'clip' && !c.parent)
                .forEach((ele, index) => {
                    ele.source = cc[index];
                });

            // console.log('TExt input field ', this.texts);
            let tt = this.texts
                .filter((item) => {
                    if (item.text == undefined) {
                        item.text = '';
                    }
                    return item.text != undefined;
                })
                .map((a) => a.text);
            // console.log('TExt input field tt', tt);
            this.videoManager.selectedVideo.template_info.contents
                .filter((c) => c.content === 'text' && !c.parent && c.id != 'address' && c.id != 'url' && c.id != 'phone')
                .forEach((ele, index) => {
                    ele.text = tt[index];
                });

            this.videoManager.selectedVideo.clips = cc;
            this.videoManager.selectedVideo.text_list = tt;

            if (!this.videoManager.selectedVideo?.email || !this.videoManager.selectedVideo?.session_id) {
                this.videoManager.selectedVideo.email = this.sUserEmail;
                this.videoManager.selectedVideo.session_id = this.sSessionId;
            }

            // console.log("Saved Video Object ", this.videoManager.selectedVideo);
            if (this.videoManager.selectedVideo?.locale_text_list) delete this.videoManager.selectedVideo.locale_text_list;

            if (this.videoManager.selectedVideo?.location_clips) delete this.videoManager.selectedVideo.location_clips;

            if (this.videoManager.selectedVideo?.location_videos) delete this.videoManager.selectedVideo.location_videos;

            if (this.videoManager.selectedVideo?.locales) delete this.videoManager.selectedVideo.locales;

            if (
                this.videoManager.selectedVideo.font_color == undefined ||
                !this.videoManager.selectedVideo.font_color ||
                this.videoManager.selectedVideo.font_color == ''
            ) {
                delete this.videoManager.selectedVideo.font_color;
            }
            if (
                this.videoManager.selectedVideo.font_file == undefined ||
                !this.videoManager.selectedVideo.font_file ||
                this.videoManager.selectedVideo.font_file == ''
            ) {
                delete this.videoManager.selectedVideo.font_file;
            }
            if (this.videoManager.selectedVideo?.others_setting?.url == false) {
                this.videoManager.selectedVideo.ci_dict.url = undefined;
            }
            if (this.videoManager.selectedVideo?.others_setting?.address == false) {
                this.videoManager.selectedVideo.ci_dict.address = undefined;
            }
            if (this.videoManager.selectedVideo?.others_setting?.phone == false) {
                this.videoManager.selectedVideo.ci_dict.phone = undefined;
            }

            if (this.videoManager.selectedVideo.ci_dict.address != undefined && this.videoManager.selectedVideo.address == undefined) {
                this.videoManager.selectedVideo.address = new address();

                this.videoManager.selectedVideo.address.line1 = this.videoManager.selectedVideo.ci_dict.address.line1
                    ? this.videoManager.selectedVideo.ci_dict.address.line1
                    : '';
                this.videoManager.selectedVideo.address.line2 = this.videoManager.selectedVideo.ci_dict.address.line2
                    ? this.videoManager.selectedVideo.ci_dict.address.line2
                    : '';
                this.videoManager.selectedVideo.address.city = this.videoManager.selectedVideo.ci_dict.address.city
                    ? this.videoManager.selectedVideo.ci_dict.address.city
                    : '';
                this.videoManager.selectedVideo.address.state = this.videoManager.selectedVideo.ci_dict.address.state
                    ? this.videoManager.selectedVideo.ci_dict.address.state
                    : '';
                this.videoManager.selectedVideo.address.postal_code = this.videoManager.selectedVideo.ci_dict.address.postal_code
                    ? this.videoManager.selectedVideo.ci_dict.address.postal_code
                    : '';
                this.videoManager.selectedVideo.address.country = this.videoManager.selectedVideo.ci_dict.address.country
                    ? this.videoManager.selectedVideo.ci_dict.address.country
                    : '';
                this.videoManager.selectedVideo.phone = this.videoManager.selectedVideo.ci_dict.phone
                    ? this.videoManager.selectedVideo.ci_dict.phone
                    : '';
                this.videoManager.selectedVideo.url = this.videoManager.selectedVideo.ci_dict.url
                    ? this.videoManager.selectedVideo.ci_dict.url
                    : '';
            }
            if (!this.videoData.vo_file) {
                this.videoData.vo_file = '';
            }
            if (!this.videoData.voice_file) {
                this.videoData.voice_file = '';
            }
            if (!this.videoData.vo_text_file) {
                this.videoData.vo_text_file = '';
            }

            this.updateCustomVideoFonts();
            // console.log( " --- this.videoManager.selectedVideo.custom_fonts ", this.videoManager.selectedVideo.custom_fonts);
            this.videoManager.createVideo(this.videoManager.selectedVideo).then((res) => {
                this.videoManager.selectedVideo['video_id'] = this.videoManager.selectedVideo.fpath;
                this.oRouter.navigateByUrl('wizard/preview');
            });
        });
        // this.InitializeJqueryCode();
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

        this.lcta = this.videoManager.selectedVideo.cta;

        this.clips = [...this.videoManager.selectedVideo.template_info.contents.filter((c) => c.content == 'clip' && !c.parent )];

        this.texts = [
            ...this.videoManager.selectedVideo.template_info.contents.filter(
                (c) => c.content == 'text' && !c.parent && c.id != 'address' && c.id != 'url' && c.id != 'phone',
            ),
        ];

        // console.log( " TEXT ITEM TOTAL ", this.texts);

        // if(elm){
        //   console.log("checking with .. ", this.videoTimeLine.nativeElement);
        //   console.log(this.videoTimeLine.nativeElement.width());
        // }
        if (this.clips) {
            this.scaleListWidth = 216 * this.clips.length - 1;
            this.scaleAudioListWidth = 216 * this.clips.length - 1;
        } else if (this.videoManager.selectedVideo.clips) {
            this.scaleListWidth = 216 * this.videoManager.selectedVideo.clips.length - 1;
            this.scaleAudioListWidth = 216 * this.videoManager.selectedVideo.clips.length - 1;

        }

        const elm = this.videoTimeLine.nativeElement;
        // console.log("object video time line ", elm.width);
        // console.log('clips', this.clips);
        // console.log('texts', this.texts);
        // console.log(
        //   " this.videoManager.selectedVideo.duration ",
        //   this.videoManager.selectedVideo.duration
        // );
        const __this = this;
        let audioTimeLineDuration = 0;
        let videoPanelWidth = 0;
        if (!this.videoTimeLine) {
            const checkVideoLoadderInterval = setInterval(() => {
                if (__this.videoTimeLine) {
                    videoPanelWidth = __this.videoTimeLine.nativeElement.clientWidth;
                    clearInterval(checkVideoLoadderInterval);
                }
            }, 1000);
        } else {
            // console.log(__this.videoTimeLine.nativeElement);
        }

        // if(!this.videoManager.selectedVideo.font_color || this.videoManager.selectedVideo.font_color == ''){
        //   this.videoManager.selectedVideo.font_color =  (this.videoManager.selectedVideo.color) ? this.videoManager.selectedVideo.color : this.videoManager.fontDefaultColor;
        // }

        this.getLengthOfTextOverLayFields();
    }

    async loadVoiceSettings() {
        // console.log(" Load video Voice Info ...");
        const __this = this;
        // console.log("Checking Voice file ...", this.videoData.voice_file_obj);
        if (
            (this.videoData.voice_file && this.videoData.voice_file != '') ||
            (this.videoData.vo_file && this.videoData.vo_file != '') ||
            (this.videoData.vo_text_file && this.videoData.vo_text_file != '')
        ) {
            let pathFile =
                this.videoData.vo_file && this.videoData.vo_file != ''
                    ? this.videoData.vo_file
                    : this.videoData.voice_file && this.videoData.voice_file != ''
                    ? this.videoData.voice_file
                    : this.videoData.vo_text_file;
            //signed_url
            if (pathFile && pathFile != '') {
                this.videoData.voice_file_obj.url = await this.oDataService.DataService_GetSignedURLForDownload(pathFile);
            } else {
                if (this.videoData.voice_file_obj) {
                    this.videoData.voice_file_obj.url = await this.oDataService.DataService_GetSignedURLForDownload(
                        this.videoData.voice_file_obj.fpath,
                    );
                }
            }

            // console.log(" signed url here vo file ", this.videoData.voice_file_obj.url);
            this.videoManager.selectedVideo.voice_file_url = this.videoData.voice_file_obj.url;
            // console.log("Voice file url loaded..");
        } else {
        }

        if (!this.videoData.voice_file && !this.videoData.vo_file && !this.videoData.vo_text_file) {
            this.videoData.voice_file_obj = undefined;
        }
        if (this.videoManager.selectedVideo.voice_file_url) {
            var audio = document.createElement('audio');
            audio.src = this.videoManager.selectedVideo.voice_file_url;
            audio.addEventListener(
                'loadedmetadata',
                function () {
                    // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
                    var duration = audio.duration;
                    let percetDifference = (duration / __this.videoManager.selectedVideo.duration) * 100;


                    // setTimeout( ()=>{
                    //     console.log( " ------ query selector size ", document.querySelector(".timeScale").clientWidth, document.querySelector(".timeScale").scrollWidth);
                    // }, 1000);
                    if(document.querySelector(".timeScale")){
                        __this.scaleListWidth = document.querySelector(".timeScale").scrollWidth;
                    }
                    let audioPanelWidth = (percetDifference * __this.scaleListWidth) / 100;
                    if (audioPanelWidth > __this.scaleListWidth) {
                        audioPanelWidth = __this.scaleListWidth;
                    }
                    // console.log('  voice over file url ', audio.duration);
                    __this.scaleAudioVoiceOverListWidth = audioPanelWidth;
                },
                false,
            );

            if (
                (this.videoData.vo_file && this.videoData.vo_file != '') ||
                (this.videoData.voice_file && this.videoData.voice_file != '') ||
                (this.videoData.vo_text_file && this.videoData.vo_text_file != '')
            ) {
                // console.log(' SET VOICE FILE - URL FOUND ...');
                this.voiceoverSource = this.videoManager.selectedVideo.voice_file_url;
            } else if (this.videoData.voice_file && this.videoData.voice_file != '') {
                this.voiceoverSource = this.videoManager.selectedVideo.voice_file_url;
            } else {
                // console.log('CLEAR VOICE OVER ...');
                this.voiceoverSource = '';
            }
            this.setVoiceOverStartFromPosition();
        } else {
            // console.log('CLEAR VOICE OVER ...');
            this.voiceoverSource = '';
        }

        if (this.videoManager.selectedVideo.music_file_url) {
            // console.log(" we have music file .....", this.videoManager.selectedVideo.music_file_url);
            try {
                this.videoManager.selectedVideo.music_file_url_loading = true;
                // __this.successMessage.displayMessage(
                //   "Loading Music File",
                //   "Loading Music File ",
                //   true
                // );
                this.bIsAudio = true;
                this.audioSource = this.videoManager.selectedVideo.music_file_url;
                var audioFile = document.createElement('audio');
                audioFile.src = this.videoManager.selectedVideo.music_file_url;

                audioFile.addEventListener(
                    'loadedmetadata',
                    function () {
                        // console.log("Data loaded fully .. hide modal ...");
                        try {
                            __this.successMessage.close();
                            __this.videoManager.selectedVideo.music_file_url_loading = false;

                            var duration = audioFile.duration;
                            let percetDifference = (duration / __this.videoManager.selectedVideo.duration) * 100;
                            let audioPanelWidth = (percetDifference * __this.scaleListWidth) / 100;
                            if (audioPanelWidth > __this.scaleListWidth) {
                                audioPanelWidth = __this.scaleListWidth;
                            }
                            __this.scaleAudioListWidth = audioPanelWidth;
                            __this.bToggleAudioButton = true;
                            __this.videoManager.selectedVideo.music_file_url_loading = false;
                        } catch (e) {
                            __this.videoManager.selectedVideo.music_file_url_loading = false;
                        }
                    },
                    false,
                );
                // console.log("Check voice Loading ...");
                this.audioSource = this.videoManager.selectedVideo.music_file_url;
                setTimeout(() => {
                    __this.videoManager.selectedVideo.music_file_url_loading = false;
                }, 10000);
            } catch (e) {
                __this.videoManager.selectedVideo.music_file_url_loading = false;
            }
        } else {
            __this.videoManager.selectedVideo.music_file_url_loading = false;
            this.bIsAudio = false;
        }
        this.video = cloneDeep(this.videoManager.selectedVideo);

        this.lTemplateCategory = this.config.Templates;

        this.VideoTimelineViewComponent_CalculateScaleBreakPoints();

        this.oUserVoiceOverList = this.config.voices;
        this.lDurationsList = [];
    }

    ngAfterViewInit() {}

    updateVoiceFileLength($event) {
        // console.log('Event here ', $event, this.videoData);
        var __this = this;
        if ($event.visual_path) {
            setTimeout(() => {
                // console.log('Check voice update ..', __this.videoData.voice_file_obj, '&& ', __this.videoData.vo_file, __this.videoData);
                if (__this.videoData.voice_file_obj && __this.videoData.vo_file) {
                    // __this.videoData.vo_file = __this.videoData.voice_file_obj.fpath;
                    // __this.videoData.voice_file = __this.videoData.voice_file_obj.fpath;
                    // __this.videoData.vo_file_url = __this.videoData.voice_file_obj.url;
                    // __this.videoData.vo_text_file = __this.videoData.voice_file_obj.url;
                    // console.log('inside voice object now voice update ..');
                    var audio = document.createElement('audio');
                    audio.src = __this.videoData.voice_file_url;

                    if (audio.duration || __this.videoData.voice_file_obj.duration) {
                        __this.calculateAudioContainerWidth(__this.videoData.voice_file_obj.duration);
                    } else {
                        audio.addEventListener(
                            'loadedmetadata',
                            function () {
                                __this.calculateAudioContainerWidth(audio.duration);
                            },
                            false,
                        );
                    }
                    // console.log(__this.videoData);
                    this.voiceoverSource = '';
                    this.voiceoverSource = __this.videoData.voice_file_obj.url;
                } else {
                    this.voiceoverSource = '';
                }
            }, 1000);
        } else {
            this.voiceoverSource = '';
            this.videoData.vo_file = undefined;
            this.videoData.voice_file = undefined;
            this.videoData.vo_file_url = undefined;
            this.videoData.vo_text_file = undefined;
        }
    }

    calculateAudioContainerWidth(duration) {
        let percetDifference = (duration / this.videoManager.selectedVideo.duration) * 100;
        let audioPanelWidth = (percetDifference * this.scaleListWidth) / 100;
        if (audioPanelWidth > this.scaleListWidth) {
            audioPanelWidth = this.scaleListWidth;
        }
        this.scaleAudioVoiceOverListWidth = audioPanelWidth;
        // console.log(' audio new duration ', duration, this.scaleAudioVoiceOverListWidth);
    }
    ngOnDestroy() {
        this.videoManager.isEditMode = false;
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.session.VideoSettingEditClip = false;
        this.session.visibleVoiceover = false;
        this.session.visibleTitle = false;
        this.session.visibleVideo = false;
        this.session.visibleAudio = false;
        this.session.visibleTagline = false;
    }

    VideoTimelineViewComponent_CalculateScaleBreakPoints() {
        this.lsVideoDuration = this.videoManager.durations;
        this.lScaleBreakPoints = [];
        let nCurrentBreakPoint: number = 0;
        const nBreakPoint: number = this.videoManager.selectedVideo.duration / 6;
        for (let nBreakPointIndex = 0; nBreakPointIndex <= 6; nBreakPointIndex++) {
            let lBreakPointPartition: string[] = [];
            const nNextbreakPoint: number = nCurrentBreakPoint + nBreakPoint;
            if (nBreakPointIndex !== 6) {
                for (
                    let nBreakPointPartitionIndex = nCurrentBreakPoint;
                    nBreakPointPartitionIndex < Math.floor(nNextbreakPoint);
                    nBreakPointPartitionIndex++
                ) {
                    lBreakPointPartition.push('span' + nBreakPointPartitionIndex);
                }
            }
            this.lScaleBreakPoints.push({
                breakpoint: nCurrentBreakPoint,
                breakPointPartition: lBreakPointPartition,
            });
            nCurrentBreakPoint += nBreakPoint;
        }
    }
    VideoTimelineViewComponent_SetVideoForm() {
        this.fLooknFeelForm = this.oFormBuilder.group({
            session_id: this.sSessionId,
            email: this.sUserEmail,
            video_id: this.sVideoId,
            template_category: [this.oLooknFeelForm.template_category, [Validators.required]],
            key_terms: [this.oLooknFeelForm.key_terms],
            template_name: [this.oLooknFeelForm.template_name],
            color: [this.oLooknFeelForm.color],
            font_color: this.videoManager.selectedVideo.font_color
                ? [this.videoManager.selectedVideo.font_color]
                : [this.videoManager.fontDefaultColor],
            oUploadedLogo: [this.oLooknFeelForm.oUploadedLogo],
            nUploadedLogoSize: [this.oLooknFeelForm.nUploadedLogoSize],
        });
        this.lKey_terms = this.oLooknFeelForm.key_terms;
        this.oPrimaryColor = this.oLooknFeelForm.color;
        this.oFontColor = this.oLooknFeelForm.font_color;
        this.sTemplateChange = this.oLooknFeelForm.template_category;
        this.oUploadedLogo = this.oLooknFeelForm.oUploadedLogo;
        this.nUploadedLogoSize = this.oLooknFeelForm.nUploadedLogoSize;
        if (this.oLooknFeelForm.oUploadedLogo) {
            this.sUploadedLogoName = this.oLooknFeelForm.oUploadedLogo.filename;
        }
    }

    VideoTimelineComponent_DragAndDropCTA(event: CdkDragDrop<any[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
        this.lcta = event.container.data;
    }

    OnAudioPlayButtonClick() {
        if (this.voiceoverWaveform) {
            this.voiceoverWaveform.pause();
        }
        this.bToggleVoiceoverButton = true;
        this.audioWaveform.play();
        this.bToggleAudioButton = false;
        this.videoClips.nativeElement.pause();
    }
    OnAudioPauseButtonClick() {
        this.audioWaveform.pause();
        this.bToggleAudioButton = true;
    }
    OnVoiceoverPlayButtonClick() {
        this.bToggleAudioButton = true;
        if (this.audioWaveform) this.audioWaveform.pause();
        if (this.voiceoverWaveform) this.voiceoverWaveform.play();
        this.bToggleVoiceoverButton = false;
        this.videoClips.nativeElement.pause();
    }
    OnVoiceoverPauseButtonClick() {
        this.voiceoverWaveform.pause();
        this.bToggleVoiceoverButton = true;
    }
    LoadAudioFile(event) {
        // console.log("VideoTimelineComponent_LoadUploadedBackgroundMusic :==========> ", event);
    }
    renderedAudioFile(event) {
        // console.log("CustomEditVideo_RenderUploadedBackgroundMusic :==========> ", event);
    }

    ReplaceText(oIncommingEmitedBlock: any) {
        if (oIncommingEmitedBlock) {
            this.selectedBlock_text = oIncommingEmitedBlock;
            this.videoManager.selectedVideo.template_info.contents.forEach((cont) => {
                if (cont.id == this.selectedBlock_text.id) {
                    cont.text = this.selectedBlock_text.text;
                }
            });
        }
        this.closeVideo();
        this.closeTitle();
    }

    RevertText() {
        this.videoManager.selectedVideo.template_info.contents.forEach((cont) => {
            if (cont.id == this.selectedBlock_text.id) {
                this.selectedBlock_text.text = cont.text;
            }
        });
        this.closeTitle();
    }

    VideoTimelineComponent_EmitedBlockToSave(oIncommingEmitedBlock: any) {
        // console.log('Called .... replaced  >>>>>>', oIncommingEmitedBlock);
        // console.log(' Clips information ', this.clips);
        this.clips.forEach((ele) => {
            if (ele.id === this.oSelectedBlock.id) {
                if (oIncommingEmitedBlock.action && oIncommingEmitedBlock.action == 'ts') {
                    if (oIncommingEmitedBlock['startfrom']) {
                        ele.ts = oIncommingEmitedBlock['ts'];
                        ele.startfrom = oIncommingEmitedBlock['startfrom'];
                        // console.log('Set ts only ...');
                    }
                } else {
                    // console.log(' ...Called ....', oIncommingEmitedBlock);
                    // console.log(' this is the selected block ..');
                    ele.source = oIncommingEmitedBlock['replaceclip'];
                    ele.thumbnail = oIncommingEmitedBlock['thumbnail'];
                    ele.url = oIncommingEmitedBlock['url'];
                    if (oIncommingEmitedBlock['ts']) {
                        // oIncommingEmitedBlock['ts'][0] = 14;
                        // oIncommingEmitedBlock['ts'][1] = 19;
                        ele.ts = oIncommingEmitedBlock['ts'];
                        if (oIncommingEmitedBlock['startfrom']) {
                            ele.startfrom = oIncommingEmitedBlock['startfrom'];
                        }
                    }
                }

                // console.log(' ELEMENT PARAM HERE ---- ', ele);
            }
        });
        this.closeVideo();
        this.closeTitle();
    }
    VideoTimelineComponent_EmitedBlockToRevert(oIncommingEmitedBlock: any) {
        // console.log(' Reverted Clip ');
        this.closeVideo();
        this.closeTitle();
    }

    VideoTimelineComponent_DragAndDropBlock(event: CdkDragDrop<any[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        this.clips = event.container.data;
    }

    Text_DragAndDropBlock(event: CdkDragDrop<any[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
        this.texts = event.container.data;
    }
    VideoTimelineComponent_SelectBlock(oIncommingBlock: any, index) {
        this.bDisplayBlockSidePanel = false;
        setTimeout(() => {
            this.oSelectedBlock = oIncommingBlock;
            this.bDisplayBlockSidePanel = true;
        }, 500);
    }
    VideoTimelineComponent_SelectBlockForText(oIncommingBlock: any, index) {
        this.oSelectedBlock = oIncommingBlock;
        this.bDisplayBlockTextSidePanel = true;
    }
    VideoTimelineComponent_SendUpdateRequest(oIncommingPayload: any) {
        this.oWebSocketService.WebSocket_RegisterEvent('user_video_result', this.VideoTimelineComponent_HandleUserVideoResponse);
        this.oWebSocketService.WebSocket_SendMessage('user_video_request', JSON.stringify(oIncommingPayload));
    }
    VideoTimelineComponent_HandleUserVideoResponse = (InComingMessage) => {
        if (JSON.parse(InComingMessage)['errorMessage']) {
            this.sDisplayResponseMessage = 'An error occurred while creating video. Please try again.';

            $('#error-popup').modal('show');
        } else {
            const oResponse = JSON.parse(InComingMessage);

            this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);

            this.oRouter.navigateByUrl('/dashboard/overview');
        }
    };
    async VideoTimelineComponent_SelectTemplateCategory(select, index) {
        let oName: any;
        this.nSelectedTemplateIndex = index;
        this.oSelectedTemplate = select;
        await this.oTemplateConfigService.TemplateConfigService_FetchTemplateCategories(this.oSelectedTemplate).then((result) => {
            this.lChangedTemplate = result;
            oName = this.lChangedTemplate[0];
        });

        this.fLooknFeelForm.patchValue({
            template_category: this.oSelectedTemplate,
            template_name: oName.sName,
        });
        this.sSessionService.cSessionService_SetLooknFeelForm(this.fLooknFeelForm.value);
    }

    VideoTimelineComponent_UpdateVideo(bUpdateVideo: boolean) {
        if (bUpdateVideo) {
            this.fContentForm = this.oSessionService.cSessionService_GetContentForm();
            this.fContentForm.lBlockConfig = this.lBlockConfigurations;
            this.fContentForm.cta = this.lcta;
            this.oSessionService.cSessionService_SetContentForm(this.fContentForm);
            const oEditVideoForm = this.oEditVideoService.EditVideoService_ReturnCombinedPayload();
            this.VideoTimelineComponent_SendUpdateRequest(oEditVideoForm);
        }
    }
    async VideoTimeline_IncomingAudioFile(event) {
        if (event.url) {
            this.bIsAudio = true;
            this.bToggleAudioButton = true;
            this.audioSource = event.url;
        } else if (!event) {
            this.bIsAudio = false;
            this.videoManager.selectedVideo.music_file = '';
        } else if (event) {
            this.bIsAudio = true;
            this.videoManager.selectedVideo.music_file = event;
            const oPlayAudioFile = await this.oDataService.DataService_GetSignedURLForDownload(event);
            this.audioSource = oPlayAudioFile;
        }
        this.closeAudio();
    }
    VideoTimeline_IncomingVoiceOverFile(event) {
        // console.log('Voice over dont ...', event);
        if (event.url) {
            this.bToggleVoiceoverButton = true;
            this.voiceoverSource = event.url;
        } else if (event.visual_path) {
            this.videoManager.selectedVideo.voice_file = event.visual_path;
        } else if (event?.defaultVoiceChange) {
            if (this.videoManager.selectedVideo?.voice_file_url) {
                this.videoManager.selectedVideo.voice_file = '';
                this.voiceoverSource = this.videoManager.selectedVideo.voice_file_url;
            }
        } else {
            this.videoManager.selectedVideo.voice_file = '';
        }
    }

    async VideoTimeLineComponent_GetIncomingVideoDetails(oEditVideoConfiguration) {
        let oMusicfile = oEditVideoConfiguration.music_file;
        if (oEditVideoConfiguration.music_file != '') {
            this.bIsAudio = true;
        } else {
            this.bIsAudio = false;
        }
        const oIncomingMusicFile = await this.oDataService.DataService_GetSignedURLForDownload(oMusicfile);
        this.audioSource = oIncomingMusicFile;
        this.oMusicFile = oEditVideoConfiguration;
        if (oEditVideoConfiguration.vo_file || oEditVideoConfiguration.voice_file) {
            let oAudioFile = oEditVideoConfiguration.vo_file ? oEditVideoConfiguration.vo_file : oEditVideoConfiguration.voice_file;
            const IncomingAudioFile = await this.oDataService.DataService_GetSignedURLForDownload(oAudioFile);
            this.voiceoverSource = IncomingAudioFile;
        } else {
            let oAudioFile = oEditVideoConfiguration.vo_text_file;
            if (oAudioFile) {
                this.voiceoverSource = await this.oDataService.DataService_GetSignedURLForDownload(oAudioFile);
            } else {
                // console.log("CLEAR VO FILE ..............................");
                this.voiceoverSource = '';
            }
        }
    }

    VideoTimelineComponent_PLayVideo(oIncommingId) {
        this.sSelectedVideoId = oIncommingId;

        this.lBlockConfigurations.forEach((element) => {
            var video = <HTMLVideoElement>document.getElementById(element.id);
            if (element.id == oIncommingId) {
                video.play();
                this.voiceoverWaveform.pause();
                this.bToggleVoiceoverButton = true;
                this.bToggleAudioButton = true;
                this.audioWaveform.pause();
            } else {
                video.pause();
            }
        });
    }

    onPaused() {
        this.bToggleAudioButton = true;
    }
    onVoiceOverPaused() {
        this.bToggleVoiceoverButton = true;
    }
    VideoTimelineComponent_GetThumbnail(oIncommingClipPath) {
        let thumb = oIncommingClipPath.substr(0, 15) + 'thumbs/' + oIncommingClipPath.substr(15, oIncommingClipPath.length);
        thumb =
            'https://storage.googleapis.com/pronto-video/' +
            thumb.replace('.mp4', '_thumb.jpg').replace('.mov', '_thumb.jpg').replace('.avi', '_thumb.jpg');
        return thumb;
    }
    async VideoTimelineViewComponent_GetClipsPreview() {}
    async VideoTimelineViewComponent_GetBlocksPreview() {
        // console.log("Current Blocks ==> ", this.lBlockConfigurations);
        for (let nBlockIndex = 0; nBlockIndex < this.lBlockConfigurations.length; nBlockIndex++) {
            const oCurrentBlock = this.lBlockConfigurations[nBlockIndex];
            if (oCurrentBlock.url == '') {
            }
        }
        this.lBlockConfigurationsCopy = cloneDeep(this.lBlockConfigurations);
    }

    VideoTimelineViewComponent_GetVideoDetails() {
        this.oWebSocketService.WebSocket_RegisterEvent('video_data_result', this.VideoTimelineViewComponent_HandleSingleVideoResponse);
        let obj = {
            email: this.sUserEmail,
            session_id: this.sSessionId,
            video_id: this.sVideoId,
        };
        this.oWebSocketService.WebSocket_SendMessage('video_data_request', JSON.stringify(obj));
    }
    VideoTimelineViewComponent_HandleSingleVideoResponse = (IncomingMessage) => {
        console.log('VideoTimelineViewComponent_HandleSingleVideoResponse : video Details => ', JSON.parse(IncomingMessage));
        if (JSON.parse(IncomingMessage)['errorMessage']) {
            console.error('VideoTimelineViewComponent_HandleSingleVideoResponse : ERROR ==>', JSON.parse(IncomingMessage)['errorMessage']);
        } else {
            let oFormatedIncommingMessage = JSON.parse(IncomingMessage);
            if (oFormatedIncommingMessage.video.block_config[0].block_file_path) {
                this.VideoTimelineViewComponent_UpdateBlockConfigurations(oFormatedIncommingMessage.video.block_config);
            } else {
                //poll over single video details until block_file_path is generated
                // this.nSetPollingTimer = setTimeout(() => {this.VideoTimelineViewComponent_GetVideoDetails()}, 10000);
            }
        }
        this.oWebSocketService.WebSocket_UnRegisterEvent('video_data_result', this.VideoTimelineViewComponent_HandleSingleVideoResponse);
    };
    async VideoTimelineViewComponent_UpdateBlockConfigurations(lIncommingBlocks: any) {
        for (let nBlockIndex = 0; nBlockIndex < lIncommingBlocks.length; nBlockIndex++) {
            const block_file_path = lIncommingBlocks[nBlockIndex].block_file_path;
            let url = await this.oDataService.DataService_GetSignedURLForDownload(block_file_path);
            let thumb = await this.oDataService.DataService_GetUserVisualThumbnail(block_file_path);
            let thumb_url = await this.oDataService.DataService_GetSignedURLForDownload(thumb);
            this.lBlockConfigurations[nBlockIndex].block_file_path = block_file_path;
            this.lBlockConfigurations[nBlockIndex].url = url;
        }
    }
    VideoTimelineComponent_RemoveHighlightBlock(event) {
        this.oSelectedBlock = null;
    }

    openAudio(): void {
        this.session.visibleAudio = true;
        this.session.visibleVideo = false;
        this.session.visibleTitle = false;
        this.session.visibleVoiceover = false;
        this.session.VideoSettingEditClip = false;

        this.session.visiblePreviewVideo = false;
        this.session.visiblePreviewOverview = false;
        this.session.visibleAnalytics = false;
        this.session.visibleVideoSettings = false;
        this.session.visibleTagline = false;
    }
    closeAudio(): void {
        this.session.visibleAudio = false;
    }

    openVideo() {
        this.session.visibleVideoSettings = true;
        // this.session.visibleVideo = true;
        this.session.visibleTitle = false;
        this.session.visibleVoiceover = false;
        this.session.visibleAudio = false;
        this.session.VideoSettingEditClip = false;

        this.session.visiblePreviewVideo = false;
        this.session.visiblePreviewOverview = false;
        this.session.visibleAnalytics = false;
        this.session.visibleTagline = false;
        // this.session.visibleVideoSettings = false;
    }
    closeVideo(): void {
        // this.session.visibleVideo = false;
        this.session.visibleVideoSettings = false;
    }
    selectedBlock_text: content;
    selectedBlock_text_index: number;
    openTitle(block, index): void {
        this.selectedBlock_text = block;
        this.selectedBlock_text_index = index;

        this.session.visibleTitle = true;
        this.session.visibleVideo = false;
        this.session.visibleVoiceover = false;
        this.session.visibleAudio = false;
        this.session.VideoSettingEditClip = false;

        this.session.visiblePreviewVideo = false;
        this.session.visiblePreviewOverview = false;
        this.session.visibleAnalytics = false;
        this.session.visibleVideoSettings = false;
        this.session.visibleTagline = false;
    }
    closeTitle(): void {
        this.session.visibleTitle = false;
    }

    openVoiceover(): void {
        this.session.visibleVoiceover = true;
        this.session.visibleAudio = false;
        this.session.visibleVideo = false;
        this.session.visibleTitle = false;
        this.session.VideoSettingEditClip = false;

        this.session.visiblePreviewVideo = false;
        this.session.visiblePreviewOverview = false;
        this.session.visibleAnalytics = false;
        this.session.visibleVideoSettings = false;
        this.session.visibleTagline = false;
    }
    closeVoiceover(): void {
        this.session.visibleVoiceover = false;
    }

    openVideoSettingEditClip(): void {
        this.session.VideoSettingEditClip = true;
        this.session.visibleVoiceover = false;
        this.session.visibleAudio = false;
        this.session.visibleVideo = false;
        this.session.visibleTitle = false;

        this.session.visiblePreviewVideo = false;
        this.session.visiblePreviewOverview = false;
        this.session.visibleAnalytics = false;
        this.session.visibleVideoSettings = false;
        this.session.visibleTagline = false;
    }
    closeVideoSettingEditClip(): void {
        this.session.VideoSettingEditClip = false;
    }
    width: number = 0;
    currentIndex: number = 260;
    selectedTime: number = 0;
    widthVidThumb = 20;
    numbers: any;
    changeIndex(event) {
        let wid = this.width1.nativeElement.getBoundingClientRect().width;
        this.width = wid;
        this.currentIndex = event;
        let valueCursorSec = (event - 240) / (this.scaleListWidth / this.videoManager.selectedVideo.duration);
        this.selectedTime = parseInt(valueCursorSec + '');
        this.widthVidThumb = (this.width + 70) / this.numbers.length;
    }

    videoThumbSize(): Number {
        if (this.videoManager.selectedVideo.aspect_ratio == '1x1') {
            return 105;
        }
        if (this.videoManager.selectedVideo.aspect_ratio == '9x16') {
            return 70;
        }
        if (this.videoManager.selectedVideo.aspect_ratio == '4x5') {
            return 88;
        }
        return 209;
    }

    getLengthOfTextOverLayFields() {
        this.videoService
            .getTemplateContent(this.videoManager.selectedVideo.template_name, 'text', this.videoManager.selectedVideo.duration)
            .then((res: any) => {
                let listLengthFields = [];
                // console.log(' Length of text list', res);
                let filteredList = res.template_data.filter((item: any) => {
                    return item.text != '_address_' && item.text != '_phone_' && item.parent == undefined;
                });
                filteredList.map((item) => {
                    if (item?.content == 'text') {
                        listLengthFields.push(
                            item.max_chars ? item.max_chars : this.videoDataStore.editVideoTextOverLayFieldsDefaultLength,
                        );
                    }
                });
                // console.log('length of fields here ', listLengthFields);
                this.videoDataStore.editVideoTextOverLayFieldsLength = listLengthFields;
            });
    }

    refreshAudioList() {
        console.log(' audio list called ...');
    }

    onVoiceItemDragReleased($event) {
        // console.log( "Drag evnet ends ", $event);
        // console.log($event.source.element);
        // console.log($event.source.element.nativeElement);
        // console.log($event.source.element?.nativeElement?.style);
        // console.log($event.source.element?.nativeElement?.style.transform);
        if ($event.source.element?.nativeElement?.style.transform) {
            let left = this.settings.transformLeft($event.source.element?.nativeElement?.style.transform);
            if (left) {
                let durationStart = (this.videoData.duration * (left / (this.scaleListWidth + 10)) * 100) / 100;
                if (durationStart % Math.round(durationStart) > 0) {
                    durationStart = Math.round(1 + durationStart);
                }
                if (this.videoData.voice_file_obj) {
                    this.videoData.voice_file_obj.start_from = durationStart < 0 ? 0 : durationStart;
                }
                // console.log(durationStart , this.settings.transformLeft($event.source.element?.nativeElement?.style.transform));
            }
        }
    }

    get videoData() {
        return this.videoManager?.selectedVideo;
    }
    setVoiceOverStartFromPosition() {
        if (this.videoData?.voice_file_obj) {
            let startFrom =
                this.videoManager.selectedVideo.voice_file_obj.start_from < 0
                    ? 0
                    : this.videoManager.selectedVideo.voice_file_obj.start_from;
            let percentVoice = (startFrom / this.videoData.duration) * 100;
            let transitionSize = (percentVoice * (this.scaleListWidth + 10)) / 100;
            setTimeout(() => {
                if (document.querySelector('.dragable-div-voiceover')) {
                    let elm = document.querySelector<HTMLElement>('.dragable-div-voiceover');
                    elm.style.transform = `translate3d(${transitionSize}px, 0px, 0px)`;
                }
            }, 1000);
        }
    }

    updateChildOfParentTitle($event) {
        if ($event.id && !$event.parent) {
            let children = this.videoManager.selectedVideo.template_info.contents.filter((item) => {
                return item.parent != undefined && item.parent == $event.id;
            });

            children.map((child) => {
                child.font = $event.font;
                child.forecolor = $event.forecolor;
                if ($event.custom_font_color) {
                    child.custom_font_color = $event.custom_font_color;
                }
                if ($event.custom_font) {
                    child.custom_font = $event.custom_font;
                }
            });
        }
    }

    updateCustomVideoFonts() {
        let vidCusFonts= [];
        let cusFontList = this.videoManager?.videoBrand?.fonts;
        if(cusFontList && cusFontList.length > 0){
            this.texts.map((text) => {
                let ind =  cusFontList.findIndex( (itm) => {
                    return  ( itm.indexOf('/' + text.font) > -1)
                });
                if((vidCusFonts.length == 0 || !vidCusFonts.includes(cusFontList[ind])) && cusFontList[ind] != undefined){
                    vidCusFonts.push(cusFontList[ind]);
                }
            });
        }

        this.videoManager.selectedVideo.custom_fonts = vidCusFonts;
    }
}
