<div class="br12 curve-bg tab-content bg-gray-9 page-shadow m-0 p-0" id="pills-tabContent">
    <div class="tab-pane fade show active">
        <div class="content_area p-0 scroll">
            <div class="row m-0">
                <div class="col-sm-12 col-md-12 col-lg-7 h-85vh ps-5 pt-4">
                    <div class="row ps-4">
                        <div class="col-sm-12">
                            <div class="formItm static_lable order_list mb-1">
                                <label for=""><span class="counts">{{ countForlouderBrand + formListStart + 4 }}.</span>Select a category for your video
                                </label>
                                <div class="radio_select_wrapp">
                                    <label class="radio_select" [ngClass]="{
                                            'disabled-radio':
                                                this.videoManger.isEditMode && category?.category != this.selectedCategory?.category
                                        }" *ngFor="let category of this.configManager.Templates"
                                        (change)="selectCategory(category)">
                                        <input type="radio" class="radio_input_element" name="template_category"
                                            [checked]="category?.category == this.selectedCategory?.category ? 'checked' : ''"
                                            [value]="category?.category" />
                                        <div class="radio_caption">
                                            <span>{{ category?.category }}</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="formItm static_lable order_list mb-2">
                                <label for=""><span class="counts">{{ countForlouderBrand + formListStart + 5}}.</span>Enter keywords for your
                                    video to help our AI find relevant content</label>
                                <div class="createTag_wrapp pt-2 mt-1">
                                    <figure class="my-1 mx-2">
                                        <img src="../../../../assets/images/svg/keyword tag.svg">
                                    </figure>
                                    <span class="tag mb-2" *ngFor="let keyterm of lKey_terms; let i = index">{{ keyterm
                                        }}
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                            class="me-1 position-relative" width="10" height="10" viewBox="0 0 24 24"
                                            style="fill: #7a7a7a; left: 5px"
                                            (click)="CreateVideoLooknFeelComponent_RemoveTagListElements(i)">
                                            <path
                                                d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z">
                                            </path>
                                        </svg>
                                    </span>
                                    <div class="tagfield createTag_wrappAdd"
                                        [ngClass]="bDisplayAddInputField ? 'd-inline-block' : 'd-none'">
                                        <input class="add-Tag" maxlength="32" #AddInputTagElement
                                            onkeypress="return event.charCode != 32" (focusout)="AddTagInputField()"
                                            (keydown)="addTagOnKeyEnter($event.keyCode)" [(ngModel)]="inputValue"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }" type="text"
                                            (change)="CreateVideoLooknFeelComponent_GetInputValue($event)" /><input
                                            class="tag-btn" type="button" value="Add" [disabled]="!inputValue"
                                            (click)="AddTagInputField()" toggle-class="#createTag_wrapp" />
                                    </div>
                                    <a *ngIf="!bDisplayAddInputField" href="javascript:void(0)"
                                        (click)="CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInput()"
                                        class="text-nowrap text-sm text-primary-4 ms-2 createTag_wrappAddBtn"
                                        toggle-class="#createTag_wrapp">
                                        <svg class="me-1" width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.26087 0.391304C6.26087 0.175193 6.08568 0 5.86957 0C5.65345 0 5.47826 0.175193 5.47826 0.391304L5.47826 5.73913H0.391304C0.175193 5.73913 0 5.91432 0 6.13043C0 6.34655 0.175193 6.52174 0.391304 6.52174H5.47826L5.47826 11.6087C5.47826 11.8248 5.65345 12 5.86956 12C6.08568 12 6.26087 11.8248 6.26087 11.6087L6.26087 6.52174H11.6087C11.8248 6.52174 12 6.34655 12 6.13043C12 5.91432 11.8248 5.73913 11.6087 5.73913H6.26087L6.26087 0.391304Z"
                                                fill="#FF8369" />
                                        </svg>
                                        Add a Keyword
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="formItm static_lable order_list mb-3">
                                <label for=""><span class="counts">{{countForlouderBrand + formListStart + 6}}.</span>Select the template that matches your preferred
                                    look and feel</label>
                                <p class="text-xs text-gray-6">
                                    These templates are just examples, your clips and content will be unique to your
                                    video.
                                </p>
                                <div class="videoimg_wrapp">

                                    <ul *ngIf="this.selectedCategory && templateCategories"
                                        class="mb-1 template-preview-{{prontoSettingService.getAspectClassName(this.videoManger.selectedVideo.aspect_ratio)}}">
                                        <li *ngFor="let template of this.configManager?.templateCategories"
                                            [ngClass]="{ 'disabled-radio': this.videoManger.isEditMode && this.selectedTemplate?.template_name != template.data.template_name, 'no-pic':template.data.className == 'no-pic' , 'active':this.selectedTemplate?.template_name == template.data.template_name }">
                                            <a href="javascript:void(0)" (click)="selectTemplate(template.data)"
                                                nz-tooltip nzTooltipPlacement="bottom"
                                                [nzTooltipTitle]="template.data.template_name">
                                                <img src="{{ template.data.thumbnail }}" class="img-fluid "
                                                    [ngClass]="{'no-pic':template.data.className == 'no-pic'}"
                                                    [ngStyle]="{width: this.videoManger.selectedVideo.aspect_ratio != '16x9'? '70%' : ''}" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div class="videoimg_wrapp">
                                    <ul *ngIf="this.selectedCategory">

                                        <li *ngFor="let template of this.templates" [ngClass]="{
                                                'disabled-radio':
                                                    this.videoManger.isEditMode &&
                                                    this.selectedTemplate?.template_name != template.template_name
                                            }"
                                            [ngClass]="this.selectedTemplate?.template_name == template.template_name ? 'active' : ''">
                                            <a href="javascript:void(0)" (click)="selectTemplate(template)" nz-tooltip
                                                nzTooltipPlacement="bottom" [nzTooltipTitle]="template.template_name">
                                                <img src="{{ template.thumbnail }}" />
                                            </a>
                                        </li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <div class="formItm static_lable order_list">
                                    <label for="">
                                        <span class="counts">{{countForlouderBrand + formListStart + 7}}.</span>Upload your logo
                                    </label>

                                    <div class="d-flex mt-3 mb-2">
                                        <input type="radio" name="logo-section" class="form-check-input custom-check"
                                            id="upload-logo" value="upload-logo"
                                            [checked]="this.videoManger.selectedVideo.logo_type == 'own'"
                                            (change)="toggleVideoLogoUploadSection('own'); toggleLogoUploadSection($event, 'own')" />
                                        <label class="text-dark me-3" for="upload-logo"
                                            style="width: auto; margin: 5px">Choose File</label>
                                        <input *ngIf="this.videoManger.selectedVideo.brand_id == undefined" type="radio"
                                            name="logo-section" class="form-check-input custom-check" id="use-logo"
                                            value="profile-logo"
                                            [checked]="this.videoManger.selectedVideo.logo_type == 'profile'"
                                            (change)="toggleVideoLogoUploadSection('profile'); toggleLogoUploadSection($event, 'profile')" />
                                        <label *ngIf="
                                                this.videoManger.selectedVideo.brand_id == undefined ||
                                                this.videoManger.selectedVideo.brand_id == ''
                                            " class="text-dark" for="use-logo" style="width: auto; margin: 5px">Use
                                            Profile Logo</label>
                                        <input
                                            *ngIf="this.videoManger.selectedVideo.brand_id && this.videoManger.selectedVideo.brand_id != ''"
                                            type="radio" name="logo-section" class="form-check-input custom-check"
                                            id="use-logo" value="profile-logo"
                                            [checked]="this.videoManger.selectedVideo.logo_type == 'brand'"
                                            (change)="toggleVideoLogoUploadSection('brand'); toggleLogoUploadSection($event, 'brand')" />
                                        <label
                                            *ngIf="this.videoManger.selectedVideo.brand_id && this.videoManger.selectedVideo.brand_id != ''"
                                            class="text-dark" for="use-logo" style="width: auto; margin: 5px">Use Brand
                                            Logo</label>
                                    </div>

                                    <div *ngIf="this.videoManger.selectedVideo.logo_type == 'profile'">
                                        <div class="media_list mt-2" *ngIf="oLogoDetails">
                                            <ul>
                                                <li class="mb-3">
                                                    <figure class="media_figure">
                                                        <span class="media"> <img [src]="oLogoDetails.url"
                                                                alt="logo" /></span>
                                                        <figcaption>
                                                            <div class="caption w-100">
                                                                <p class="text-xs title">
                                                                    {{ oLogoDetails.filename | slice : 0 : 30 }}
                                                                </p>
                                                                <p class="text-xs sub_title">
                                                                    {{
                                                                    CreateVideoLooknFeelComponent_ReturnFileSize(oLogoDetails.size)
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </figcaption>
                                                    </figure>
                                                </li>
                                            </ul>
                                        </div>
                                        <div *ngIf="!oLogoDetails" style="width: 50%"
                                            class="alert alert-danger d-flex align-items-center alert-dismissible fade show"
                                            role="alert">
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                                                    fill="#842029" />
                                            </svg>

                                            <div>
                                                Please upload a profile logo
                                                <button type="button" class="btn-close" data-bs-dismiss="alert"
                                                    aria-label="Close">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                                            fill="#FF8369" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="this.videoManger.selectedVideo.logo_type == 'own'">
                                        <!-- Show own Logo -->
                                        <span>
                                            <button id="custom-button-svg" (click)="openFileUploadModal()"
                                                class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow d-flex align-items-center">
                                                <span class="upload-icon"></span>
                                                Upload
                                            </button>

                                            <p class="text-xm ps-1 font-brand-logo sm">
                                                Supported formats (.jpg, .png)
                                            </p>
                                            <!-- Uploaded Logo file -->
                                            <div class="media_list mt-2" *ngIf="oUploadedLogo">
                                                <ul>
                                                    <li class="mb-3">
                                                        <figure class="media_figure">
                                                            <span class="media"> <img [src]="oUploadedLogo.url? oUploadedLogo.url : oUploadedLogo.thumbnail_signed_url"
                                                                    alt="logo" /></span>
                                                            <figcaption>
                                                                <div class="caption w-100">
                                                                    <!-- <p class="text-xs title">{{oUploadedLogo.filename | slice:0:30}}</p>
                                                                                <p class="text-xs sub_title"> {{CreateVideoLooknFeelComponent_ReturnFileSize(nUploadedLogoSize)}}</p> -->
                                                                    <p *ngIf="
                                                                            oUploadedLogo?.name != undefined && oUploadedLogo?.name != ''
                                                                        " class="text-xs title"
                                                                        style="color: rgba(75, 76, 82, 0.876)">
                                                                        {{ oUploadedLogo?.name | slice : 0 : 30 }}
                                                                    </p>
                                                                    <p *ngIf="oUploadedLogo?.size != undefined && oUploadedLogo?.size > 0"
                                                                        class="text-xs sub_title">
                                                                        {{ oUploadedLogo?.size | formatFileSize : false
                                                                        }}
                                                                    </p>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </li>
                                                </ul>
                                            </div>
                                        </span>
                                    </div>
                                    <div *ngIf="this.videoManger.selectedVideo.logo_type == 'brand'">
                                        <!-- Show Brand Logo  -->
                                        <span *ngIf="this.videoManger.selectedVideo.brand_id != ''">
                                            <button id="custom-button-svg" (click)="openSelectBrandLogo()"
                                                class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow d-flex align-items-center py-3">
                                                Select
                                            </button>
                                        </span>
                                        <span *ngIf="brandUploadedSecondLogo && currentUploadedLogoSection">
                                            <div class="media_list mt-2" *ngIf="brandUploadedSecondLogo">
                                                <ul>
                                                    <li class="mb-3">
                                                        <figure class="media_figure">
                                                            <span class="media">
                                                                <img [src]="brandUploadedSecondLogo.url"
                                                                    alt="logo" /></span>
                                                            <figcaption>
                                                                <div class="caption w-100">
                                                                    <p class="text-xs title">
                                                                        {{ brandUploadedSecondLogo.filename | slice : 0
                                                                        : 30 }}
                                                                    </p>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </li>
                                                </ul>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="bSecondLogo" class="col-sm-6 col-md-6 col-lg-6">
                                <div class="formItm static_lable order_list">
                                    <label for=""> <span class="counts">{{countForlouderBrand + formListStart + 7}}.</span>Upload secondary logo </label>

                                    <div class="d-flex mt-3 mb-2">
                                        <input type="radio" name="second-logo-section"
                                            class="form-check-input custom-check-second" id="upload-second-logo"
                                            value="upload-second-logo" checked />
                                        <label class="text-dark" for="upload-second-logo"
                                            style="width: auto; margin: 5px">Upload</label>
                                    </div>
                                    <span>
                                        <button data-bs-toggle="modal" id="custom-button-svg"
                                            data-bs-target="#uploadSecondaryModal"
                                            class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow d-flex align-items-center">
                                            <span class="upload-icon"></span>
                                            Upload
                                        </button>
                                        <!-- Uploaded Logo file -->
                                        <div class="media_list mt-2" *ngIf="oUploadedSecondLogo">
                                            <ul>
                                                <li class="mb-3">
                                                    <figure class="media_figure">
                                                        <span class="media"> <img [src]="oUploadedSecondLogo.url"
                                                                alt="logo" /></span>
                                                        <figcaption>
                                                            <div class="caption w-100">
                                                                <p class="text-xs title">
                                                                    {{ oUploadedSecondLogo.filename | slice : 0 : 30 }}
                                                                </p>
                                                                <p class="text-xs sub_title">
                                                                    {{
                                                                    CreateVideoLooknFeelComponent_ReturnFileSize(
                                                                    nUploadedSecondLogoSize
                                                                    )
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </figcaption>
                                                    </figure>
                                                </li>
                                            </ul>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <div class="formItm static_lable order_list">
                                    <label for="">
                                        <span class="counts me-2">{{countForlouderBrand + formListStart + 8}}.</span>Choose Color
                                    </label>
                                </div>
                                <div>
                                    <div class="theme-color d-flex mt-1" *ngIf="videoColorLoaded">
                                        <ul class="m-0 ps-0 pe-4">
                                            <label class="d-block text-md text-gray-2 pb-3">Theme Color</label>
                                            <li>
                                                <div class="clr-field"
                                                    [style.color]="videoThemeColor ? videoThemeColor : defaultVideoColor">
                                                    <button aria-labelledby="clr-open-label"></button>
                                                    <input class="form-check-input coloris" type="checkbox"
                                                        id="checkboxNoLabel"
                                                        [value]="videoThemeColor ? videoThemeColor : defaultVideoColor"
                                                        [(ngModel)]="videoThemeColor"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        (change)="setColorToProperty($event, 'theme')"
                                                        aria-label="..." />
                                                    <figure class="color-pic no-bg">
                                                        <img class="color-picker"
                                                            [ngClass]="{'white': (videoThemeColor != '#ffffff' && videoThemeColor != '#FFFFFF' && videoThemeColor != undefined)}"
                                                            *ngIf="
                                                                !this.videoManger.selectedVideo.theme_colors ||
                                                                this.videoManger.selectedVideo.theme_colors.length == 0
                                                            " src="../assets/images/icons/color-picker.svg" />

                                                        <img [ngClass]="{
                                                                white:
                                                                    videoThemeColor != '#ffffff' &&
                                                                    videoThemeColor != '#FFFFFF' &&
                                                                    videoThemeColor != undefined
                                                            }" *ngIf="
                                                                this.videoManger.selectedVideo.theme_colors &&
                                                                this.videoManger.selectedVideo.theme_colors.length > 0
                                                            " class="color-picker"
                                                            src="../assets/images/icons/check-icon.svg" />
                                                    </figure>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="m-0 ps-0 pe-4">
                                            <label class="d-block text-md text-gray-2 pb-3">Font Color</label>
                                            <li>
                                                <div class="clr-field" [style.color]="
                                                        videoFontColors && videoFontColors.length > 0
                                                            ? videoFontColors[0].color
                                                            : videoManger.fontDefaultColor
                                                    ">
                                                    <button aria-labelledby="clr-open-label"></button>
                                                    <input class="form-check-input coloris" type="checkbox"
                                                        id="checkboxNoLabel" [value]="
                                                            videoFontColors && videoFontColors.length > 0
                                                                ? videoFontColors[0].color
                                                                : videoManger.fontDefaultColor
                                                        " [(ngModel)]="
                                                            videoFontColors && videoFontColors.length > 0
                                                                ? videoFontColors[0].color
                                                                : videoManger.fontDefaultColor
                                                        " [ngModelOptions]="{
                                                            standalone: true
                                                        }" (change)="setColorToProperty($event, 'font')"
                                                        aria-label="..." />
                                                    <figure class="color-pic no-bg">
                                                        <img class="color-picker"
                                                            *ngIf="!this.videoManger.selectedVideo.font_color"
                                                            [ngClass]="{
                                                                white:
                                                                    videoFontColors[0].color != '#ffffff' &&
                                                                    videoFontColors[0].color != '#FFFFFF' &&
                                                                    videoFontColors[0].color != undefined
                                                            }" src="../assets/images/icons/color-picker.svg" />

                                                        <img *ngIf="this.videoManger.selectedVideo.font_color"
                                                            class="color-picker"
                                                            src="../assets/images/icons/check-icon.svg" [ngClass]="{
                                                                white:
                                                                    videoFontColors[0].color != '#ffffff' &&
                                                                    videoFontColors[0].color != '#FFFFFF' &&
                                                                    videoFontColors[0].color != undefined
                                                            }" />
                                                    </figure>
                                                </div>
                                            </li>
                                        </ul>

                                        <!-- <ul class="ms-2" *ngIf="
                                                secondaryColors &&
                                                secondaryColors.length > 0
                                            ">
                                            <label class="d-block text-md text-gray-2 pb-3">Font Colors</label>
                                            <li *ngFor="
                                                    let secondaryClr of secondaryColors;
                                                    let i = index
                                                ">
                                                <div class="clr-field" [style.color]="
                                                        secondaryClr.color
                                                            ? secondaryClr.color
                                                            : videoManger.fontDefaultColor
                                                    ">
                                                    <button aria-labelledby="clr-open-label"></button>
                                                    <input class="form-check-input coloris" [value]="
                                                            secondaryClr?.color
                                                                ? secondaryClr?.color
                                                                : videoManger.fontDefaultColor
                                                        " (change)="
                                                            setColorToProperty(
                                                                $event,
                                                                'secondary',
                                                                i
                                                            )
                                                        " type="checkbox" id="checkboxNoLabel" aria-label="..." />
                                                    <figure class="color-pic">
                                                        <img *ngIf="
                                                                !secondaryClr?.color
                                                            " src="../assets/images/icons/color-picker.svg" />
                                                        <img *ngIf="
                                                                secondaryClr?.color
                                                            " src="../assets/images/icons/check-icon.svg" />
                                                    </figure>
                                                </div>
                                            </li>
                                        </ul> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Alert for Response Error Message End -->
                </div>

                <div class="col-sm-12 col-md-12 col-lg-5 p-5">
                    <p class="text-gray-2 text-lg">Template Preview</p>
                    <div id="container-view">
                        <li>
                            <div class="video-outer look-feel-video">
                                <div id="TemplateThumbnail" class="video_wrapper video_wrapper_full js-videoWrapper">
                                    <span>
                                        <video controls loop #previewTemplate controlsList="nodownload noplaybackrate"
                                            class="videoIframe js-videoIframe"
                                            *ngIf="this.selectedTemplate?.preview_url"
                                            [src]="this.selectedTemplate?.preview_url" title="" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></video>
                                    </span>
                                    <figure class="video-baner">
                                        <img *ngIf="this.selectedTemplate?.thumbnail"
                                            [src]="this.selectedTemplate?.thumbnail | safe" alt="video"
                                            [ngStyle]="{width: (this.videoManger.selectedVideo.aspect_ratio == '9x16' || this.videoManger.selectedVideo.aspect_ratio =='4x5')? 'auto' : '100%'}" />
                                    </figure>
                                    <button *ngIf="this.selectedTemplate?.thumbnail" class="videoPoster js-videoPoster"
                                        (click)="PreviewTemplate()"></button>
                                </div>
                            </div>
                        </li>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wtermarklogo">
        <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120" />
    </div>
</div>

<!-- upload media -->
<!-- <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload a Logo</h5>
                <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group files">
                    <div class="tab-content" id="pills-tabContent">
                        <div appDragDropFileUpload (fileDropped)="startLogoUploadProcess($event, 'dragdrop')" class="tab-pane fade show active" id="pills-device" role="tabpanel"
                            aria-labelledby="pills-device-tab">

                            <div class="file-drop-area">

                                <span class="upload-icon m-auto"></span>
                                <span class="choose-file-button">Upload a File (0 files)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                    <!-- <span  class="file-message"> {{sUploadedLogoName}} </span>  -->
<!-- <input #file class="file-input" type="file" accept="image/*"  (change)="startLogoUploadProcess($event)">
                            </div>
                        </div>

                        <ul class="d-flex mb-3" id="pills-tab" role="tablist">
                            <li class="col" role="presentation">
                                <button (click)="file.click()" id="pills-device-tab" data-bs-toggle="pill" data-bs-target="#pills-device" type="button" role="tab" aria-controls="pills-device" aria-selected="true">
                                    <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                                    <span>My Device</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- upload secondary logo modal -->
<div class="modal fade" id="uploadSecondaryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload secondary logo</h5>
                <button type="button" #closeSecondModal class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group files">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-device" role="tabpanel"
                            aria-labelledby="pills-device-tab">
                            <div class="file-drop-area">
                                <span class="upload-icon m-auto"></span>
                                <span class="choose-file-button">Upload a File (0 files)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br />
                                    drag & drop your file here
                                </span>
                                <span class="file-message">
                                    {{ sUploadedSecondLogoName }}
                                </span>
                                <input #file2 class="file-input" type="file" accept="image/*"
                                    (change)="CreateVideoLooknFeelComponent_UploadSecondaryLogoFile($event)" />
                            </div>
                        </div>
                        <ul class="nav mb-3" id="pills-tab" role="tablist">
                            <li class="col nav-item" role="presentation">
                                <button class="nav-link active" (click)="file2.click()" id="pills-device-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-device" type="button" role="tab"
                                    aria-controls="pills-device" aria-selected="true">
                                    <img src="../assets/images/business_Profile/device.png" alt="tab-icon" />
                                    <span>My Device</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- upload secondary logo modal end -->
<!-- error modal -->
<div class="modal fade" id="error-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog width-483 modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5 class="mb-4">{{ sDisplayResponseErrorMessage }}</h5>
                <div class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
                    <div class="col-1 p-0">
                        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path
                                    d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602 16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29 26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747 3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365 14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365 12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993 7.3043C14.6062 7.3043 15.1095 7.80441 15.1095 8.40747V15.7619Z"
                                    fill="#FF8369"></path>
                                <path
                                    d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398 1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0 18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366 2.32Z"
                                    fill="#FF8369"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="28" height="29" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div class="col-11 p-0">
                        <p class="text-sm text-gray-3 mb-0 ms-2">
                            {{ sDisplayResponseErrorMessage }}
                        </p>
                    </div>
                </div>

                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal"
                    routerLink="/dashboard/myvideos">Go Back</a>
            </div>
        </div>
    </div>
</div>
<!-- error modal end -->
<nz-modal [(nzVisible)]="showLogoUploadModal" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="false"
    [nzKeyboard]="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <!-- <button type="button" class="btn-close cust-close closeRightBtn" (click)="hideUploadingPopUP()"></button> -->

                <h5>Logo File Uploading</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">Your Logo is uploading, Please wait.</p>
                <!-- <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" >Ok</a> -->
            </div>
        </div>
    </div>
</nz-modal>
<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="this.showBrandLogoSelectionPopup" nzCentered [nzWidth]="895" [nzFooter]="null"
    [nzClosable]="true" (nzOnCancel)="closeSelectBrandLogo()">
    <app-brand-logo-list (onSelectBrandLogo)="onSelectBrandLogo($event)" *ngIf="this.showBrandLogoSelectionPopup"
        [brand_id]="this.brand_id">
    </app-brand-logo-list>
</nz-modal>
<!-- Modal pop-up End-->