import { OnboardingDataService } from './../services/dataServices/onboarding-data.service';
import { voice } from './../models/video';
import { browser } from 'protractor';
import { SearchData } from 'src/app/models/admin';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiURL } from 'src/app/conf/api-path';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { OnboardingManagerService } from '../Managers/onboarding-manager.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VoiceEditorPopupComponent } from '../shared/voice-editor-popup/voice-editor-popup.component';
import { VideoVoiceOverSelectionComponent } from '../shared/components/video-voice-over-selection/video-voice-over-selection.component';

@Injectable({
    providedIn: 'root',
})
export class VoiceDataStore {
    public confirmSelectedVoice = new BehaviorSubject([]);
    public updateFavoriteListObservable = new BehaviorSubject([]);
    SelectionConfirmationmessage: string;
    SelectionConfirmationtitle: string;
    showSelectionConfirmation: boolean;
    public favoriteVoiceArray = [];
    public SelectedVoiceArray = [];
    voiceOverList = [];
    public limitEssential = 16;
    public reamingVoices: any;
    public allowPremium = true;
    public lastSelectedVoiceId = undefined;
    private headingEssential = 'Essentials Voices';
    private headingPremium = 'Premium Voices';
    private subHeadingEssential = 'Access a foundational library of 24 unique voice avatars to bring your content to life.';
    private subHeadingPremium =
        'Exclusively for Enterprise subscriptions, enjoy unrestricted access to our entire voice avatar collection. Elevate your content with Enterprise Voices.';

    public favoriteVoiceOverList = this.defaultVoiceTabsObj();
    public selectedVoiceOverList = this.defaultVoiceTabsObj();
    public browseVoiceList = this.defaultVoiceTabsObj();
    public searchedVoiceList = this.defaultVoiceTabsObj();
    private selectedVoiceOverObservable = new BehaviorSubject([]);
    private favoritesVoiceOverObservable = new BehaviorSubject([]);
    public voiceVideoPreviewVideoSrc = '';
    public breadcrumbOptions: any = [
        { name: 'Browse', filter: 'browse' },
        { name: 'Selected', filter: 'selected' },
        { name: 'Favorites', filter: 'favorites' },
    ];

    public voiceAttributes: any = [
        {
            attributeName: 'REGIONAL ACCENTS',
            filters: [
                { name: 'Australia', id: '', isActive: false },
                { name: 'Canada', id: '', isActive: false },
                { name: 'Brazil', id: '', isActive: false },
                { name: 'United States - Appalachia', id: '', isActive: false },
                { name: 'Maxico', id: '', isActive: false },
                { name: 'Australia', id: '', isActive: false },
                { name: 'United States - Mountain West', id: '', isActive: false },
                { name: 'Maxico', id: '', isActive: false },
                { name: 'Canada', id: '', isActive: false },
                { name: 'Australia', id: '', isActive: false },
            ],
        },
        {
            attributeName: 'VOICE CHARACTERISTICS',
            filters: [
                { name: 'Approachable', id: '', isActive: false },
                { name: 'Authoritative', id: '', isActive: false },
                { name: 'Calm', id: '', isActive: false },
                { name: 'Casual', id: '', isActive: false },
                { name: 'Charismatic', id: '', isActive: false },
                { name: 'Clear', id: '', isActive: false },
                { name: 'Comforting', id: '', isActive: false },
                { name: 'Confident', id: '', isActive: false },
                { name: 'Crisp', id: '', isActive: false },
                { name: 'Deadpan', id: '', isActive: false },
                { name: 'Casual', id: '', isActive: false },
                { name: 'Charismatic', id: '', isActive: false },
                { name: 'Clear', id: '', isActive: false },
                { name: 'Comforting', id: '', isActive: false },
                { name: 'Confident', id: '', isActive: false },
                { name: 'Crisp', id: '', isActive: false },
                { name: 'Deadpan', id: '', isActive: false },
            ],
        },
        {
            attributeName: 'PERFORMANCE STYLES',
            filters: [
                { name: 'Character', id: '', isActive: false },
                { name: 'Conversational', id: '', isActive: false },
                { name: 'Custom', id: '', isActive: false },
                { name: 'Narration', id: '', isActive: false },
                { name: 'Promo', id: '', isActive: false },
            ],
        },
    ];

    constructor(private onBoarding: OnboardingManagerService, private dialog: MatDialog) {}
    updateSelectedListObservable(val: any) {
        this.selectedVoiceOverObservable.next(val);
    }
    updateFavoritesListObservable(val: any) {
        this.favoritesVoiceOverObservable.next(val);
    }

    getFavoritesVoices() {
        return this.favoritesVoiceOverObservable.asObservable();
    }
    getSelectedVoices() {
        return this.selectedVoiceOverObservable.asObservable();
    }

    showSelectionConfirmationModal(messageTitle: string, message: any, showDialogue: boolean) {
        this.SelectionConfirmationtitle = messageTitle;
        this.SelectionConfirmationmessage = message;
        this.showSelectionConfirmation = showDialogue;
    }

    voiceOverSelectVoice(val: any) {
        this.confirmSelectedVoice.next(val);
    }

    getSelectedVoicesId() {
        let idsSelectedVoices = [];
        idsSelectedVoices = this.selectedVoiceOverList.essentials.voices.filter((item) => item.selected);
        idsSelectedVoices = idsSelectedVoices.concat(this.selectedVoiceOverList.premium.voices.filter((item) => item.selected));
        return idsSelectedVoices;
    }

    resetVoiceBrowseSelection(id, favorite, select) {
        if (favorite != undefined) {
            console.log('Reset Favorites data ....', favorite);
            this.browseVoiceList.essentials.voices.map((voice, i) => {
                if (voice._id.$oid == id) {
                    voice.favorite = favorite;
                }
            });
            this.searchedVoiceList.essentials.voices.map((voice, i) => {
                if (voice._id.$oid == id) {
                    voice.favorite = favorite;
                }
            });

            this.browseVoiceList.premium.voices.map((voice, i) => {
                if (voice._id.$oid == id) {
                    voice.favorite = favorite;
                }
            });
            this.searchedVoiceList.premium.voices.map((voice, i) => {
                if (voice._id.$oid == id) {
                    voice.favorite = favorite;
                }
            });
        }

        if (select != undefined) {
            this.browseVoiceList.premium.voices.map((selectedvoice, i) => {
                if (selectedvoice._id.$oid == id) {
                    selectedvoice.selected = select;
                }
            });
            this.searchedVoiceList.premium.voices.map((selectedvoice, i) => {
                if (selectedvoice._id.$oid == id) {
                    selectedvoice.selected = select;
                }
            });
            this.browseVoiceList.essentials.voices.map((selectedvoice, i) => {
                if (selectedvoice._id.$oid == id) {
                    selectedvoice.selected = select;
                }
            });
            this.searchedVoiceList.essentials.voices.map((selectedvoice, i) => {
                if (selectedvoice._id.$oid == id) {
                    selectedvoice.selected = select;
                }
            });
        }
    }

    setFavoritesVoicesFromProfileObject(list: any[]) {

        if (list) {
            list.map((voice) => {
                if (voice.license == 'premium') {
                    voice.favorite = true;
                    this.favoriteVoiceOverList.premium.voices.push(voice);
                } else {
                    voice.favorite = true;
                    this.favoriteVoiceOverList.essentials.voices.push(voice);
                }
            });
        }


            if (this.onBoarding?.profile?.selected_voices && this.onBoarding?.profile?.selected_voices.length > 0) {
            this.onBoarding?.profile?.selected_voices.forEach((selectedVoice) => {
                if (selectedVoice.license == 'premium') {
                    this.favoriteVoiceOverList.premium.voices.forEach((fvrtVoice, i) => {
                        if (fvrtVoice._id.$oid == selectedVoice._id.$oid) {
                            this.favoriteVoiceOverList.premium.voices.splice(i, 1);
                            fvrtVoice.selected = true;
                            this.favoriteVoiceOverList.premium.voices.push(fvrtVoice);
                        }
                    });
                } else {
                    this.favoriteVoiceOverList.essentials.voices.forEach((fvrtVoice, i) => {
                        if (fvrtVoice._id.$oid == selectedVoice._id.$oid) {
                            this.favoriteVoiceOverList.essentials.voices.splice(i, 1);
                            fvrtVoice.selected = true;
                            this.favoriteVoiceOverList.essentials.voices.push(fvrtVoice);
                        }
                    });
                }
            });
            }

    }

    setSelectedVoicesFromProfileObject(list: any[]) {
        if (list) {
            list.map((voice) => {
                if (voice.license == 'premium') {
                    voice.selected = true;
                    this.selectedVoiceOverList.premium.voices.push(voice);
                } else {
                    voice.selected = true;
                    this.selectedVoiceOverList.essentials.voices.push(voice);
                }
            });
        }

        if(this.onBoarding?.profile?.favourite_voices && this.onBoarding?.profile?.favourite_voices.length > 0){
            this.onBoarding?.profile?.favourite_voices.map((favoriteVoice)=>{
                if (favoriteVoice.license == 'premium') {
                this.selectedVoiceOverList.premium.voices.map((selectedVoice, i)=>{
                    if(favoriteVoice._id.$oid == selectedVoice._id.$oid){
                        this.selectedVoiceOverList.premium.voices.splice(i, 1);
                        selectedVoice.favorite = true;
                        this.selectedVoiceOverList.premium.voices.push(selectedVoice);
                    }
                })}
                else{
                    this.selectedVoiceOverList.essentials.voices.map((selectedVoice, i)=>{
                        if(favoriteVoice._id.$oid == selectedVoice._id.$oid){
                            this.selectedVoiceOverList.essentials.voices.splice(i, 1);
                            selectedVoice.favorite = true;
                            this.selectedVoiceOverList.essentials.voices.push(selectedVoice);
                        }
                    })
                }
            })
        }

    }

    setVoiceLimits(plan) {
        // console.log(' check voices ... limit');
        this.limitEssential = 6;
        this.allowPremium = false;
        this.browseVoiceList.premium.disabled = true;
        this.searchedVoiceList.premium.disabled = true;

        if (plan == 'business') {
            this.limitEssential = 6;
        }
        if (plan == 'business_plus') {
            this.limitEssential = 12;
        }
        if (plan == 'enterprise') {
            this.limitEssential = 64;
            this.allowPremium = true;
            this.browseVoiceList.premium.disabled = false;
            this.searchedVoiceList.premium.disabled = false;
        }
    }

    defaultVoiceTabsObj() {
        return {
            essentials: {
                isActive: true,
                disabled: false,
                header: this.headingEssential,
                headerDetails: this.subHeadingEssential,
                voices: [],
            },
            premium: {
                isActive: false,
                disabled: this.onBoarding?.profile?.plan == 'enterprise' ? true : false,
                header: this.headingPremium,
                headerDetails: this.subHeadingPremium,
                voices: [],
            },
        };
    }
    voiceConfirmationPopUp(allowedVoiceSlection: boolean) {
        if (allowedVoiceSlection) {
            this.showSelectionConfirmationModal(
                'Confirm Your Voice Selection',
                "Your voice choice is about to be locked in. Once confirmed, it's unchangeable.Ensure it perfectly matches your content's style.",
                true,
            );
        } else {
            this.showSelectionConfirmationModal(
                'Voice Limit Reached',
                "You've reached your voice limit for this plan. Your choices are now fixed.",
                true,
            );
        }
    }

    getIndexOfFavorite(id: any) {
        let indexEs = this.favoriteVoiceOverList.essentials.voices.findIndex((item) => item._id.$oid == id);
        let indexPre = this.favoriteVoiceOverList.premium.voices.findIndex((item) => item._id.$oid == id);
        return { es_id: indexEs, prem_id: indexPre };
    }
    getIndexOfSelected(id: any) {
        let indexEs = this.selectedVoiceOverList.essentials.voices.findIndex((item) => item._id.$oid == id);
        let indexPre = this.selectedVoiceOverList.premium.voices.findIndex((item) => item._id.$oid == id);
        return { es_id: indexEs, prem_id: indexPre };
    }

    pushNewFavorite(voice, type) {
        if (type == 'essential') {
            if (!this.favoriteVoiceOverList.essentials.voices) {
                this.favoriteVoiceOverList.essentials.voices = [];
            }
            this.favoriteVoiceOverList.essentials.voices.push(voice);
        } else {
            if (!this.favoriteVoiceOverList.premium.voices) {
                this.favoriteVoiceOverList.premium.voices = [];
            }
            this.favoriteVoiceOverList.premium.voices.push(voice);
        }
    }

    updateUserVoicesFromProfile(profile, clear = false) {
        this.setFavoritesVoicesFromProfileObject((profile?.favourite_voices && !clear) ? profile?.favourite_voices : []);
        this.setSelectedVoicesFromProfileObject((profile?.selected_voices && !clear ) ? profile?.selected_voices : []);
        this.setVoiceLimits(profile?.plan);
        this.reamingVoices = this.limitEssential - (profile?.selected_voices.length ? profile?.selected_voices.length : 0 );
    }
    openVoiceEditorComponent(media: any, options: any, callback: any) {
        if (media.dummy) {
            media.url = "/assets/audio/test.mp3";

            media.title = "titleVoiceapth";
            media.type = 'voice';
            media.start_point = 2;
            media.duration = 133;
            media.vo_mute = false;
            // media.vo_volume = 0;
            // media.vo_fade_in = 70;
            // media.vo_fade_out = 50;
            media.vo_apply_fading = false;

        }
        if (!media.start_point) {
            media.start_point = 0;
        }
        if (!media.type) {
            media.type = 'voice';
        }
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '800px';
        dialogConfig.height = '400px';
        dialogConfig.panelClass = 'media-editing-tool-component';
        dialogConfig.closeOnNavigation = true;

        dialogConfig.data = {
            heading: 'Edit Voice',
            media: media
        };


        let dialogModal = this.dialog.open(VoiceEditorPopupComponent, dialogConfig);

        dialogModal.afterClosed().subscribe((data: any) => {
            // console.log("return data loaded ");
            callback({ type: 'success' , data});
        });
    }

    openVoiceOverPopUp(media: any, options: any, callback: any) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '800px';
        dialogConfig.height = '400px';
        dialogConfig.panelClass = 'media-editing-tool-component';
        dialogConfig.closeOnNavigation = true;

        dialogConfig.data = {
            heading: 'Edit Voice',
            media: media,
            isLocation : true
        };


        let dialogModal = this.dialog.open(VideoVoiceOverSelectionComponent, dialogConfig);

        dialogModal.afterClosed().subscribe((data: any) => {
            if(data == undefined){
                callback({ type: 'error' , data});
            } else {
                callback({ type: data?.action , data});
            }

        });
    }

}
