// import { AppRoutingModule } from '../app-routing.module';
import { locale } from './account';
import { address } from './common';

export class videoLight {
    email: string;
    session_id: string;
    fpath: string;
    owner?: string;
    video_id: string;
    font_color?: string;
    key_terms?: [];
    template_name?: string;
    sap_video_duration?: number;
    theme_colors?: any[];
}
export class video {
    _id?: string;
    email: string;
    session_id: string;
    fpath: string;
    owner?: string;
    video_id: string;
    public_url: string;
    comments: string[];
    user_clip_id: string = '';
    likes: number = 0;
    views: number = 0;
    publish_status: string;
    about: string;
    aspect_ratio: string;
    font_color: string;
    template_category: string;
    template_info: template_info;
    user_images: string[];
    user_clips: [];
    ci_dict: ci_dict;
    cta: [];
    use_pronto_clips: boolean = false;
    bucket_name: string;
    title: string;
    duration: number;
    key_terms: [];
    text_list: string[];
    fineprints_list?: string[];
    clips?: string[];
    address: address;
    paths_dict: { [key: string]: string };
    music_file: string;
    music_file_url?: string;
    music_file_url_loading?: boolean;
    created: string;
    days_ago: number;
    thumb: string;
    color: string;
    logo_img: string;
    second_logo_img: string;
    logo_img_url: string;
    second_logo_img_url: string;
    automated: boolean;
    template_name: string;
    locales: locale[];
    location_clips: [];
    locale_text_list: [];
    location_videos: [];
    vo_text: string;
    vo_text_file: string;
    selected_voice: string;
    voice_file: string;
    vo_file?: string;
    vo_file_url?: string;
    vo_gender?: string;
    voice_file_url?: string;
    voice_file_obj?: any;
    block_config: block_config[];
    url?: string;
    video_url?: string;
    phone?: string;
    schedule?: any;
    scheduleVideos?: video[];
    thumbnail?: string;
    status?: string;
    loadingUrl?: boolean;
    //block_clips:block_clips[];
    block_texts: block_texts[] = [];
    loadingThumbUrl?: boolean;
    is_hide_duration: boolean = false;
    last_modified?: string;
    watermark: boolean = false;
    block_clips: block_clips[];
    AllLocaleSelected: boolean = false;
    isLocationVideo: boolean = false;
    videoIndex?: any = 0.3;
    brand: string | any;
    uploaded_video_id?: string;
    sap_config: sap_config;
    sap_video_duration?: number;
    sap_video_id?: string;
    sap_video_url: string;
    sap_video_url_thumb: string;
    brand_id: string | any;
    video_preview_url: string | any;
    primary_color?: string;
    secondary_colors?: any[];
    theme_colors?: any[];
    font_file?: string | any;
    logo_type?: string | any;
    sap_secondary_logo?: string;
    sap_secondary_logo_signed_url?: string;
    sap_tag_line?: string;
    ars?: any;
    edit_title?: false;
    title_temp? : string;
    show_player? : boolean;
    selected_template_category_data? : any;
    voice_wellsaid_selection? : string;
    voice_wellsaid_id? : string;
    vo_mute? : boolean;
    others_setting?: any;
    try_now?: boolean;
    step?: string;
    custom_fonts? : any[];
    voiceGenSize ? : number;
    fps?: any;
    fpsInput?: any;
    //  { url?: boolean , address?: boolean, phone?: boolean }
}
export class sap_config {
    // duration= 30;
    clips: overlayclip[] = [];
    texts: overlayTexts[] = [];
}

export class overlayclip {
    video_id?: string;
    video_url?: string;
    video_thumbnail?: string;
    ts: number[] = [];
    width: number = 0;
    left: number = 0;
    loadingURL = false;
}

export class overlayTexts {
    video_id: string;
    text: string[] = [];
    ts: number[] = [];
    width: number = 0;
    left: number = 0;
    forecolor? :any;
    font?: any;
    custom_font?: boolean;
    custom_font_color?: boolean;
}

export class template_info {
    contents: content[];
    transforms: any;
}

export class content {
    content: string;
    id: string;
    pos: number[];
    size: number[];
    text: string;
    font: string;
    source: string;
    stretch: string;
    ts: number[];
    parent?: any;
    url: string;
    thumbnail: string;
    loadingUrl: boolean;
    loadingThumbnail: boolean;
    effects: any;
    startfrom? : any;
    forecolor? :any;
    custom_font?:boolean;
    custom_font_color?:boolean;
}
export class block_clips {
    isSelected: boolean = false;
    clips: string[];
    editclips: clip[];
    loadingUrl: boolean = false;
    isDisable: boolean = false;
}
export class clip {
    clip: string;
    url: string;
    thumbnail: string;
    thumb: string;
    ts: number[];
}
export class block_texts {
    isDisable: boolean = false;
    no_texts: number;
    texts: string[] = [];
}
export class ci_dict {
    url: string;
    address: address;
    phone?: any;
}
export class block_config {
    effects: effect[];
    block_args: block_args;
    id: string = 'blockID';
    clip_idx: [];
    block_file_path: string;
    text_idx: [];
    loadingUrl?: boolean = false;
    loadingThumbnail?: boolean = false;
    thumbnail?: string;
    thumb?: string;
    url?: string;
    block_name: string;
    video_gen: video_gen;
}

export class video_gen {
    content_info: content_info;

    count: number;
    duration: number;
    transform_info: any;
}
export class content_info {
    clips: clip;
    content: any;
    images: any;
}
export class voice {
    cps: number;
    gender: string;
    sample_voice: string;
    voice_name: string;
    name: string;
}
export class block_args {
    num_clips: number;
    clip_pos: [];
    clip_trans: [];
    block_gen: any;
    video_gen: video_gen;
    clip_times: [];
    clip_dims: [];
    num_texts: number;
    text_pos: [];
    text_times: [];
    text_titles: [];
    duration: number;
    fps: Number;
    clip_effects: effect[];
}

export class effect {
    effect_name: string;
    effect_args: effect_args;
}

export class effect_args {
    font_size: number;
    color: string;
    font: string;
    theme: string;
    vh: number;
    vw: number;
    pos: [];
    fps: number;
}

export class scheduledVideos {
    public date: string;
    public videos: video[] = [];
    public isActive: boolean = false;
}

export class duration {
    id: string;
    public duration: number;
    public num_text: number;
    public pos: number[];
    public size: number[];
    public textlist: textinput[] = [];
    public placehoder: string;
    public placeholder: string;
    public content?: string;
    public parentIndex?: number;
    public index?: number;
    public visible?: boolean;
    public isMultiLiner?: boolean;
    max_chars?: number;
    partOfId?: number;
    oid?: number;
    originalSortOrder?: number;
    fillChildAutoType?: boolean;
    ts?: any[];
}

export class textinput {
    public placeholder: string;
    public value: string;
}
